import _ from 'lodash';

export const DateUtil = {
  /**
   * date 형 변수를 YYYYMMDD로 변경
   * @param date
   * @returns
   */
  getYYYYMMDD: (date: string | undefined = undefined) => {
    const d1: Date = date ? new Date(date) : new Date();

    const monthStr =
      d1.getMonth() + 1 < 10 ? '0' + (d1.getMonth() + 1) : d1.getMonth() + 1;
    const dateStr = d1.getDate() < 10 ? '0' + d1.getDate() : d1.getDate();

    return `${d1.getFullYear()}${monthStr}${dateStr}`;
  },
  /**
   * date 형 변수를 YYYYMMDD로 변경
   * @param date
   * @returns
   */
  getDotFormat: (date: string | undefined = undefined) => {
    const d1: Date = date ? new Date(date) : new Date();

    const monthStr =
      d1.getMonth() + 1 < 10 ? '0' + (d1.getMonth() + 1) : d1.getMonth() + 1;
    const dateStr = d1.getDate() < 10 ? '0' + d1.getDate() : d1.getDate();

    return `${d1.getFullYear()}.${monthStr}.${dateStr}`;
  },

  /**
   * YYYYMM 형 문자열을 파싱하여 해당월 마지막일자 구하기
   * @param yyyymm
   * @returns
   */
  getLastDate: (yyyymm: string) => {
    const d1: Date = new Date(
      Number(yyyymm.substring(0, 4)),
      Number(yyyymm.substring(4, 6)),
      0
    );
    return (
      yyyymm.substring(0, 4) + yyyymm.substring(4, 6) + String(d1.getDate())
    );
  },

  /**
   * date형 문자열을 년월일 문자열으로 변경
   * @param date
   * @returns
   */
  getKoreanFormDate: (date: string | undefined) => {
    if (!date) {
      return 'Invalid Date';
    }

    return `${date.substring(0, 4)}년 ${Number(date.substring(4, 6))}월 ${Number(
      date.substring(6, 8)
    )}일`;
  },

  /**
   * date형 문자열을 년월 문자열으로 변경
   * @param date
   * @returns
   */
  getKoreanFormMonth: (date: string | undefined) => {
    if (!date) {
      return 'Invalid Date';
    }

    const d1: Date = new Date();
    d1.setFullYear(Number(date.substring(0, 4)));
    d1.setMonth(Number(date.substring(4, 6)) - 1);
    return `${d1.getFullYear()}년 ${d1.getMonth() + 1}월`;
  },

  /**
   * date를 문자열로
   * @param d1
   * @returns
   */
  dateToString: (d1: Date) => {
    const monthStr =
      d1.getMonth() + 1 < 10 ? '0' + (d1.getMonth() + 1) : d1.getMonth() + 1;
    const dateStr = d1.getDate() < 10 ? '0' + d1.getDate() : d1.getDate();

    return `${d1.getFullYear()}${monthStr}${dateStr}`;
  },

  /**
   * 시작 끝 사이의 날짜를 전부 리턴
   * @param from
   * @param to
   * @returns
   */
  getDateListBetween: (from: string, to: string) => {
    if (_.isEmpty(from) || _.isEmpty(to)) {
      return [];
    }

    const d1: Date = new Date();
    d1.setFullYear(Number(to.substring(0, 4)));
    d1.setMonth(Number(to.substring(4, 6)) - 1);
    d1.setDate(Number(to.substring(6, 8)));

    const rtnArr: string[] = [];

    let fromStr = '';
    const d2: Date = new Date();
    d2.setFullYear(Number(from.substring(0, 4)));
    d2.setMonth(Number(from.substring(4, 6)) - 1);
    d2.setDate(Number(from.substring(6, 8)));

    while (to !== fromStr) {
      const monthStr =
        d2.getMonth() + 1 < 10 ? '0' + (d2.getMonth() + 1) : d2.getMonth() + 1;
      const dateStr = d2.getDate() < 10 ? '0' + d2.getDate() : d2.getDate();
      fromStr = `${d2.getFullYear()}${monthStr}${dateStr}`;
      rtnArr.push(fromStr);

      d2.setDate(d2.getDate() + 1);
    }

    return rtnArr;
  },
};
