/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import mThumbDown from '../../assets/img/m-thumb-down.svg';
import mThumbUp from '../../assets/img/m-thumb-up.svg';
import { getBoardContents } from '../../common/api/board';
import { BoardContentList } from '../../common/data/data-type';
import { DateUtil } from '../../common/util/date';
import { boardRecoil } from '../../components/Header';
import { Pager } from '../../components/Pager';
import News from './News';

export const ComTypeInfo = [
  {
    comType: 'INCOME',
    communityTitle: '수익인증',
  },
  {
    comType: 'PAY',
    communityTitle: '매매법공유',
  },
  {
    comType: 'REAL',
    communityTitle: '실시간지표',
  },
  {
    comType: 'FREE',
    communityTitle: '자유게시판',
  },
  {
    comType: 'HUMOR',
    communityTitle: '유머게시판',
  },
  {
    comType: 'NEWS',
    communityTitle: '뉴스게시판',
  },
  {
    comType: 'CS',
    communityTitle: '고객센터 문의',
  },
];

export const CommunityBoard = () => {
  const boardRecoilValue = useRecoilValue(boardRecoil);
  const [dataList, setDataList] = useState<BoardContentList[]>([]);
  const navigate = useNavigate();
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(1);
  const [searchKeyword, setSearchKeyword] = useState('');

  useEffect(() => {
    const fetchBoards = async () => {
      try {
        const response = await getBoardContents(
          ComTypeInfo[boardRecoilValue].comType,
          searchKeyword,
          page
        );
        setDataList(response?.boards ?? []);
        setTotalCount(response?.totalCount ?? 0);
      } catch (error) {
        console.error('Failed to fetch boards:', error);
      }
    };

    fetchBoards();
  }, [page, searchKeyword, boardRecoilValue]);

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  const handleSearchChange = (e: any) => {
    setSearchKeyword(e.target.value);
  };

  return (
    <>
      <div className="board pc">
        <div className="title">
          <h3>{ComTypeInfo[boardRecoilValue].communityTitle}</h3>
          {ComTypeInfo[boardRecoilValue].comType !== 'NEWS' ? (
            <div className="inner">
              <div className="select-area">
                <select className="select" name="" id="">
                  <option value="">제목</option>
                </select>
              </div>
              <div className="search">
                <input
                  type="text"
                  className="input-search"
                  placeholder="검색어를 입력해주세요."
                  value={searchKeyword}
                  onChange={handleSearchChange}
                ></input>
              </div>
              {/* <Link to="/content"> */}
              <div className="write-btn-area">
                <Link
                  to="#/"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate('/write-content', {
                      state: {
                        title: ComTypeInfo[boardRecoilValue].communityTitle,
                        boardType: ComTypeInfo[boardRecoilValue].comType,
                      },
                    });
                  }}
                >
                  <button className="write-btn" type="button">
                    글쓰기
                  </button>
                </Link>
              </div>
            </div>
          ) : null}
        </div>
        {ComTypeInfo[boardRecoilValue].comType !== 'NEWS' ? (
          <div className="content">
            <table>
              <thead>
                <tr>
                  <th>번호</th>
                  <th>제목</th>
                  <th>작성자</th>
                  <th>작성일</th>
                  <th>조회수</th>
                  <th>Like</th>
                  <th>Dislike</th>
                </tr>
              </thead>
              <tbody>
                {dataList?.map((e) => (
                  <BoardContent
                    key={e.boardId}
                    no={e.boardId}
                    createdByName={e.createdByName}
                    boardId={e.boardId}
                    boardTitle={e.boardTitle}
                    replyCnt={e.replyCnt}
                    viewCnt={e.viewCnt}
                    likeCnt={e.likeCnt}
                    dislikeCnt={e.dislikeCnt}
                    createAt={e.createAt}
                  />
                ))}
              </tbody>
            </table>
            <Pager
              currentPage={page}
              totalItems={totalCount}
              onPageChange={handlePageChange}
              itemsPerPage={20}
            />
          </div>
        ) : (
          <div className="content">
            <News widgetId="NEWS" />
          </div>
        )}
      </div>
      <div className="mobile-board mobile">
        <div className="mobile-board-title">
          <h3>{ComTypeInfo[boardRecoilValue].communityTitle}</h3>
          <div className="write-btn-area">
            {ComTypeInfo[boardRecoilValue].comType !== 'NEWS' ? (
              <button
                className="write-btn"
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  navigate('/write-content', {
                    state: {
                      title: ComTypeInfo[boardRecoilValue].communityTitle,
                      boardType: ComTypeInfo[boardRecoilValue].comType,
                    },
                  });
                }}
              >
                글쓰기
              </button>
            ) : null}
          </div>
        </div>
        {ComTypeInfo[boardRecoilValue].comType !== 'NEWS' ? (
          <>
            <div className="mobile-board-list">
              {dataList?.map((e) => (
                <MobileBoardContent
                  key={e.boardId}
                  onClick={() => {
                    navigate(`/content/${e.boardId}`);
                  }}
                  createdByName={e.createdByName}
                  boardId={e.boardId}
                  boardTitle={e.boardTitle}
                  replyCnt={e.replyCnt}
                  viewCnt={e.viewCnt}
                  likeCnt={e.likeCnt}
                  dislikeCnt={e.dislikeCnt}
                  createAt={e.createAt}
                />
              ))}
            </div>
            <Pager
              currentPage={page}
              totalItems={totalCount}
              onPageChange={handlePageChange}
              itemsPerPage={20}
            />
          </>
        ) : (
          <div className="mobile-board-list">
            <News widgetId="NEWS" />
          </div>
        )}
      </div>
    </>
  );
};

/**
 * 게시판 목록
 * @param param0
 * @returns
 */
const BoardContent = ({
  no,
  createdByName,
  boardTitle,
  boardId,
  replyCnt,
  viewCnt,
  likeCnt,
  dislikeCnt,
  createAt,
}: BoardContentList) => (
  <tr>
    <td>{no}</td>
    <td className="title">
      <Link to={`/content/${boardId}`}>{boardTitle}</Link>{' '}
      <span className="span-red">[{replyCnt ?? '0'}]</span>
    </td>
    <td>{createdByName}</td>
    <td>{DateUtil.getDotFormat(createAt)}</td>
    <td>{viewCnt}</td>
    <td>{likeCnt}</td>
    <td>{dislikeCnt}</td>
  </tr>
);
const MobileBoardContent = ({
  createdByName,
  boardTitle,
  boardId,
  replyCnt,
  viewCnt,
  likeCnt,
  dislikeCnt,
  createAt,
  onClick,
}: BoardContentList & { onClick: any }) => (
  <div id={boardId} className="txt-one" onClick={onClick}>
    <div className="txt-title">
      <h4>{boardTitle}</h4>
      <ul>
        <li>
          작성자<span>{createdByName}</span>
        </li>
        <li>
          작성일<span>{DateUtil.getDotFormat(createAt)}</span>
        </li>
        <li>
          조회수<span>{viewCnt}</span>
        </li>
        <li>
          <img src={mThumbUp} alt="" />
          <span>{likeCnt}</span>
        </li>
        <li>
          <img src={mThumbDown} alt="" />
          <span>{dislikeCnt}</span>
        </li>
      </ul>
    </div>
    {replyCnt && Number(replyCnt) > 0 ? (
      <div className="txt-comment">
        <p>댓글 {replyCnt}</p>
      </div>
    ) : null}
  </div>
);
