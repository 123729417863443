/* eslint-disable @typescript-eslint/no-explicit-any */
// import '../assets/css/modal.css'; // 모달 디자인을 위한 CSS 파일

const ConfirmModal = ({
  isOpen,
  message,
  onConfirm,
  onCancel,
}: {
  isOpen: boolean;
  message: string;
  onConfirm: any;
  onCancel: any;
}) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <p>{message}</p>
        <div className="modal-buttons">
          <button className="modal-confirm-btn" onClick={onConfirm}>
            확인
          </button>
          <button className="modal-cancel-btn" onClick={onCancel}>
            취소
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmModal;
