import { useState } from 'react';
import { Link } from 'react-router-dom';
import '../../assets/css/points.css'; // 포인트 구매 안내 페이지 전용 CSS
import coinIcon from '../../assets/img/points.png'; // 황금색 코인 아이콘 경로를 설정하세요

export const PurchasePoints = () => {
  const [price, setPrice] = useState<string | null>(null);

  return (
    <main className="main">
      <section className="points-section">
        <div className="inner">
          <div className="title">
            <h3>SETRADE 포인트 구매 안내</h3>
          </div>

          <div className="points-container">
            {/* 코인 아이콘 및 포인트들 */}
            {[1000, 3000, 10000, 50000, 100000].map((points, index) => (
              <div
                key={index}
                className="points-item"
                onMouseEnter={() => setPrice(points.toLocaleString())}
                onMouseLeave={() => setPrice(null)}
              >
                <div className="coin-icon-container">
                  <img
                    src={coinIcon}
                    alt="포인트 아이콘"
                    className="coin-icon"
                  />
                  <span className="points">
                    + {points.toLocaleString()} points
                  </span>
                </div>
                {/* <div className="price">KRW \10,000</div> */}
              </div>
            ))}
          </div>

          {/* 계좌정보 */}
          <div className="account-info">
            <p className="price">{price ? `KRW : ${price}` : '-'}</p>
            <p>계좌번호: XXXXXXXXXX</p>
            <p>예금주: XXXXXXX</p>
          </div>

          {/* 돌아가기 버튼 */}
          <div className="back-button-container">
            <Link to="/">
              <button className="back-button">돌아가기</button>
            </Link>
          </div>
        </div>
      </section>
    </main>
  );
};
