/* eslint-disable @typescript-eslint/no-explicit-any */
import api from './api';

export const getUserInfo = async (userId: number) => {
  const response = await api.get(`/users/${userId}`);
  return response?.data;
};

export const getUserId = async (email: string, cellNumber: string) => {
  const response = await api.post(`/findUserId`, { email, cellNumber });
  return response?.data;
};

export const updateUserInfo = async (userInfo: any) => {
  const response = await api.put(`/users/update`, userInfo);
  return response?.data;
};

export const registerUser = async (userInfo: any) => {
  const response = await api.post(`/users`, userInfo);
  return response?.data;
};

export const resetPassword = async (loginId: string, cellNumber: string) => {
  const response = await api.post('/users/reset-password', {
    loginId,
    cellNumber,
  });
  return response?.data;
};

export const sendEmailVerification = async (email: string) => {
  const response = await api.post('/users/sendEmailVerification', { email });
  return response?.data;
};

export const sendEmailAuthnum = async (email: string) => {
  const response = await api.post('/users/sendEmailVerificationRegisterUser', {
    email,
  });
  return response?.data;
};
export const sendSmsAuthnum = async (cellNumber: string) => {
  const response = await api.post('/users/sendSmsVerificationRegisterUser', {
    cellNumber,
  });
  return response?.data;
};

export const sendSmsVerificationWithEmail = async (
  email: string,
  cellNumber: string
) => {
  const response = await api.post('/users/sendSmsVerification', {
    email,
    cellNumber,
  });
  return response?.data;
};

export const sendSmsVerificationWithLoginId = async (
  loginId: string,
  cellNumber: string
) => {
  const response = await api.post('/users/sendSmsVerificationWithUserId', {
    loginId,
    cellNumber,
  });
  return response?.data;
};
