import React, { useCallback, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../../assets/css/signup.css';
import { verifyAuthKeyRegister } from '../../common/api/auth';
import {
  registerUser,
  sendEmailAuthnum,
  sendSmsAuthnum,
} from '../../common/api/user';
import {
  validateEmail,
  validateNickname,
  validatePassword,
  validatePhone,
  validateUserId,
} from '../../common/util/validator';
import AgreementPopup from '../../components/AgreementPopup';
import ConfirmModal from '../../components/ConfirmModal';
import OverlayLoader from '../../components/OverlayLoader';

export const SignUp = () => {
  const navigate = useNavigate();
  const [userId, setUserId] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [nickname, setNickname] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [emailCode, setEmailCode] = useState('');
  const [phoneCode, setPhoneCode] = useState('');
  const [emailVerified, setEmailVerified] = useState(false);
  const [phoneVerified, setPhoneVerified] = useState(false);
  const [emailTimer, setEmailTimer] = useState(0);
  const [phoneTimer, setPhoneTimer] = useState(0);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showAgreementPopup, setShowAgreementPopup] = useState(false); // 새로운 상태
  const [agreementContent, setAgreementContent] = useState(''); // 새로운 상태

  const [errors, setErrors] = useState({
    userId: '',
    password: '',
    confirmPassword: '',
    nickname: '',
    email: '',
    phone: '',
  });
  const [agreements, setAgreements] = useState({
    agreeAll: false,
    ageAgree: false,
    useAgree: false,
    personalAgree: false,
    choiceAgree: false,
  });

  const handleOpenPopup = useCallback((content: string) => {
    setAgreementContent(content);
    setShowAgreementPopup(true);
  }, []);

  const handleCert = useCallback(async () => {
    try {
      await verifyAuthKeyRegister(email, emailCode);
      setEmailVerified(true);
      setEmailTimer(0);
    } catch (error) {
      setErrors((prev) => ({
        ...prev,
        email: '인증번호가 올바르지 않습니다.',
      }));
    }
  }, [email, emailCode]);

  const handleSmsCert = useCallback(async () => {
    try {
      await verifyAuthKeyRegister(phone, phoneCode);
      setPhoneVerified(true);
      setPhoneTimer(0);
    } catch (error) {
      setErrors((prev) => ({
        ...prev,
        email: '인증번호가 올바르지 않습니다.',
      }));
    }
  }, [phone, phoneCode]);

  const handleAllAgree = useCallback(
    (checked: boolean) => {
      setAgreements({
        agreeAll: checked,
        ageAgree: checked,
        useAgree: checked,
        personalAgree: checked,
        choiceAgree: checked,
      });
    },
    [setAgreements]
  );

  const handleSingleAgree = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { id, checked } = e.target;
      setAgreements((prev) => ({
        ...prev,
        [id]: checked,
        agreeAll: id === 'agreeAll' ? checked : prev.agreeAll,
      }));

      if (!checked && id !== 'agreeAll') {
        setAgreements((prev) => ({
          ...prev,
          agreeAll: false,
        }));
      } else if (
        checked &&
        id !== 'agreeAll' &&
        agreements.ageAgree &&
        agreements.useAgree &&
        agreements.personalAgree &&
        agreements.choiceAgree
      ) {
        setAgreements((prev) => ({
          ...prev,
          agreeAll: true,
        }));
      }
    },
    [setAgreements, agreements]
  );

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, field: string) => {
      const { value } = e.target;

      switch (field) {
        case 'userId':
          setUserId(value);
          setErrors((prev) => ({
            ...prev,
            userId: validateUserId(value)
              ? ''
              : '아이디 형식이 틀립니다. 영문숫자 6자이상 12자이하',
          }));
          break;
        case 'password':
          setPassword(value);
          setErrors((prev) => ({
            ...prev,
            password: validatePassword(value)
              ? ''
              : '비밀번호 형식이 틀립니다. 영문숫자 특수문자 8자이상 20자이하',
          }));
          break;
        case 'confirmPassword':
          setConfirmPassword(value);
          setErrors((prev) => ({
            ...prev,
            confirmPassword:
              value === password ? '' : '비밀번호가 일치하지 않습니다.',
          }));
          break;
        case 'nickname':
          setNickname(value);
          setErrors((prev) => ({
            ...prev,
            nickname: validateNickname(value)
              ? ''
              : '닉네임 형식이 틀립니다. 한글 5자, 영문숫자 10자까지 입력가능합니다.',
          }));
          break;
        case 'email':
          setEmail(value);
          setErrors((prev) => ({
            ...prev,
            email: validateEmail(value) ? '' : '이메일 형식이 잘못되었습니다.',
          }));
          break;
        case 'phone':
          setPhone(value);
          setErrors((prev) => ({
            ...prev,
            phone: validatePhone(value)
              ? ''
              : '전화번호 형식이 잘못되었습니다.',
          }));
          break;
        default:
          break;
      }
    },
    [setUserId, setErrors, setConfirmPassword, setNickname, password]
  );

  const handleEmailVerification = useCallback(async () => {
    if (validateEmail(email)) {
      try {
        await sendEmailAuthnum(email);
        setEmailVerified(false);
        setEmailTimer(300); // 5분 타이머
        startEmailTimer();
        setErrors((prev) => ({
          ...prev,
          email: '',
        }));
      } catch (error) {
        setEmailVerified(false);
        setErrors((prev) => ({
          ...prev,
          email: '이메일 인증발송에 실패했습니다.',
        }));
      }
    }
  }, [setEmailVerified, setErrors, email]);

  const handlePhoneVerification = useCallback(async () => {
    if (validatePhone(phone) && emailVerified) {
      try {
        await sendSmsAuthnum(phone);
        setPhoneVerified(false);
        setPhoneTimer(90); // 1분 30초 타이머
        startPhoneTimer();
        setErrors((prev) => ({
          ...prev,
          phone: '',
        }));
      } catch (error) {
        setPhoneVerified(false);
        setErrors((prev) => ({
          ...prev,
          phone: 'SMS인증이 실패했습니다.',
        }));
      }
    } else if (!emailVerified) {
      alert('이메일 인증을 먼저 해주세요.');
    }
  }, [setPhoneVerified, emailVerified, phone, setErrors]);

  const startEmailTimer = () => {
    const interval = setInterval(() => {
      setEmailTimer((prev) => {
        if (prev <= 1) {
          clearInterval(interval);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);
  };

  const startPhoneTimer = () => {
    const interval = setInterval(() => {
      setPhoneTimer((prev) => {
        if (prev <= 1) {
          clearInterval(interval);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);
  };

  const handleRegisterUser = async () => {
    if (
      Object.values(errors).some((error) => error !== '') ||
      !emailVerified ||
      !phoneVerified
    ) {
      alert('입력한 정보를 다시 확인해주세요.');
      return;
    }
    setShowConfirmModal(true);
  };

  const handleConfirm = async () => {
    setLoading(true);
    try {
      await registerUser({
        loginId: userId,
        pwd: password,
        userName: nickname,
        email,
        cellNumber: phone,
      });
      setLoading(false);
      alert('회원가입 성공');
      navigate('/home');
    } catch (error) {
      setLoading(false);
      alert('회원가입 실패');
    }
  };

  const handleCancel = () => {
    if (userId || password || confirmPassword || nickname || email || phone) {
      setShowCancelModal(true);
    } else {
      navigate('/home');
    }
  };

  return (
    <>
      <main className="main">
        <section className="join">
          <div className="inner">
            <div className="title">
              <h3>
                <span>SETRADE COMMUNITY 회원가입</span>
              </h3>
            </div>
            <div className="content">
              <div className="content-inner">
                <div className="user-pw-area">
                  <input
                    type="text"
                    placeholder="아이디"
                    className={`join-input ${errors.userId ? 'error' : ''}`}
                    value={userId}
                    onChange={(e) => handleInputChange(e, 'userId')}
                  />
                  {errors.userId && (
                    <small className="error-text">{errors.userId}</small>
                  )}
                  <div className="pw-txt">
                    <p>* 영어 소문자 및 숫자로만 입력 가능합니다.</p>
                  </div>
                </div>
                <div className="user-pw-area">
                  <input
                    type="password"
                    placeholder="비밀번호"
                    className={`join-input ${errors.password ? 'error' : ''}`}
                    value={password}
                    onChange={(e) => handleInputChange(e, 'password')}
                  />
                  {errors.password && (
                    <small className="error-text">{errors.password}</small>
                  )}
                  <input
                    type="password"
                    placeholder="비밀번호 확인"
                    className={`join-input ${errors.confirmPassword ? 'error' : ''}`}
                    value={confirmPassword}
                    onChange={(e) => handleInputChange(e, 'confirmPassword')}
                  />
                  {errors.confirmPassword && (
                    <small className="error-text">
                      {errors.confirmPassword}
                    </small>
                  )}
                  <div className="pw-txt">
                    <p>* 비밀번호는 8 ~ 20 자리로 입력해주세요.</p>
                    <p>
                      * 비밀번호는 문자, 숫자, 특수문자 중 2가지 이상의 조합으로
                      입력해주세요.
                    </p>
                  </div>
                </div>
                <div className="user-pw-area">
                  <input
                    type="text"
                    placeholder="닉네임"
                    className={`join-input ${errors.nickname ? 'error' : ''}`}
                    value={nickname}
                    onChange={(e) => handleInputChange(e, 'nickname')}
                  />
                  {errors.nickname && (
                    <small className="error-text">{errors.nickname}</small>
                  )}
                </div>
                <div className="user-email-area">
                  <input
                    type="text"
                    placeholder="이메일"
                    className={`join-input ${errors.email ? 'error' : ''}`}
                    value={email}
                    onChange={(e) => handleInputChange(e, 'email')}
                  />
                  <button
                    type="button"
                    className="cert-btn"
                    onClick={handleEmailVerification}
                    disabled={!validateEmail(email)}
                  >
                    이메일 본인인증
                  </button>
                </div>
                {errors.email && (
                  <small className="error-text">{errors.email}</small>
                )}
                {emailTimer > 0 && (
                  <>
                    <div className="user-email-area">
                      <input
                        type="text"
                        placeholder="인증번호 입력"
                        className="join-input"
                        value={emailCode}
                        onChange={(e) => setEmailCode(e.target.value)}
                      />
                      <button
                        type="button"
                        className="cert-btn"
                        onClick={handleCert}
                      >
                        인증
                      </button>
                    </div>
                    <span>
                      {emailVerified
                        ? '인증완료'
                        : emailTimer > 0
                          ? `${emailTimer}초 남음`
                          : '인증시간 만료'}
                    </span>
                  </>
                )}
                <div className="user-tel-area">
                  <input
                    type="number"
                    placeholder="전화번호"
                    className={`join-input ${errors.phone ? 'error' : ''}`}
                    value={phone}
                    onChange={(e) => handleInputChange(e, 'phone')}
                  />
                  <button
                    type="button"
                    className="cert-btn"
                    onClick={handlePhoneVerification}
                    disabled={!validatePhone(phone) || !emailVerified}
                  >
                    휴대전화 인증
                  </button>
                </div>
                {errors.phone && (
                  <small className="error-text">{errors.phone}</small>
                )}
                {phoneTimer > 0 && (
                  <>
                    <div className="user-tel-area">
                      <input
                        type="text"
                        placeholder="인증번호 입력"
                        className="join-input"
                        value={phoneCode}
                        onChange={(e) => setPhoneCode(e.target.value)}
                      />
                      <button
                        type="button"
                        className="cert-btn"
                        onClick={handleSmsCert}
                      >
                        인증
                      </button>
                    </div>
                    <span>
                      {phoneVerified
                        ? '인증완료'
                        : phoneTimer > 0
                          ? `${phoneTimer}초 남음`
                          : '인증시간 만료'}
                    </span>
                  </>
                )}
                <div className="agree-area">
                  <ul className="agree-list">
                    <li>
                      <input
                        type="checkbox"
                        id="agreeAll"
                        checked={agreements.agreeAll}
                        onChange={(e) => handleAllAgree(e.target.checked)}
                      ></input>
                      <label htmlFor="agreeAll">전체동의</label>
                    </li>
                    <li>
                      <div className="chk-area">
                        <input
                          type="checkbox"
                          id="ageAgree"
                          checked={agreements.ageAgree}
                          onChange={handleSingleAgree}
                        ></input>
                        <label htmlFor="ageAgree">
                          만 19세 이상입니다
                          <span className="span-red">(필수)</span>
                        </label>
                      </div>
                    </li>
                    <li>
                      <div className="chk-area">
                        <input
                          type="checkbox"
                          id="useAgree"
                          checked={agreements.useAgree}
                          onChange={handleSingleAgree}
                        ></input>
                        <label htmlFor="useAgree">
                          이용 약관 동의<span className="span-red">(필수)</span>
                        </label>
                      </div>
                      <div className="agree-content">
                        <Link
                          to={'#/'}
                          onClick={() => handleOpenPopup('이용 약관 동의')}
                        >
                          내용보기
                        </Link>
                      </div>
                    </li>
                    <li>
                      <div className="chk-area">
                        <input
                          type="checkbox"
                          id="personalAgree"
                          checked={agreements.personalAgree}
                          onChange={handleSingleAgree}
                        ></input>
                        <label htmlFor="personalAgree">
                          개인정보 수집 및 이용동의
                          <span className="span-red">(필수)</span>
                        </label>
                      </div>
                      <div className="agree-content">
                        <Link
                          to={'#/'}
                          onClick={() =>
                            handleOpenPopup('개인정보 수집 및 이용동의')
                          }
                        >
                          내용보기
                        </Link>
                      </div>
                    </li>
                    <li>
                      <div className="chk-area">
                        <input
                          type="checkbox"
                          id="choiceAgree"
                          checked={agreements.choiceAgree}
                          onChange={handleSingleAgree}
                        ></input>
                        <label htmlFor="choiceAgree">
                          선택정보 수집 및 이용동의
                          <span className="span-red">(필수)</span>
                        </label>
                      </div>
                      <div className="agree-content">
                        <Link
                          to={'#/'}
                          onClick={() =>
                            handleOpenPopup('선택정보 수집 및 이용동의')
                          }
                        >
                          내용보기
                        </Link>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="join-btn-area">
                  <button
                    type="button"
                    className="join-btn"
                    onClick={handleRegisterUser}
                  >
                    회원가입
                  </button>
                  <button
                    type="button"
                    className="cancel-btn"
                    onClick={handleCancel}
                  >
                    취소
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      {loading && <OverlayLoader />}
      <ConfirmModal
        isOpen={showConfirmModal}
        message={'입력하신 내용으로 회원가입 하시겠습니까?'}
        onConfirm={handleConfirm}
        onCancel={() => setShowConfirmModal(false)}
      />
      <ConfirmModal
        isOpen={showCancelModal}
        message={'입력한 모든 내용이 초기화 됩니다.'}
        onConfirm={() => navigate('/home')}
        onCancel={() => setShowCancelModal(false)}
      />
      {showAgreementPopup && (
        <AgreementPopup
          content={agreementContent}
          onClose={() => setShowAgreementPopup(false)}
        />
      )}
    </>
  );
};
