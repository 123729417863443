/* eslint-disable @typescript-eslint/no-explicit-any */
import _ from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

const drawChart = (container: React.MutableRefObject<any>, div: string) => {
  const divObj = chartType.filter((e) => e.label === div);

  const script = document.createElement('script');
  script.src =
    'https://s3.tradingview.com/external-embedding/embed-widget-symbol-overview.js';
  script.type = 'text/javascript';
  script.async = true;
  script.innerHTML = `
          {
            "symbols": [
                ${!_.isEmpty(divObj) ? divObj[0].text : ''}
            ],
            "chartOnly": true,
            "width": "100%",
            "height": "100%",
            "locale": "kr",
            "colorTheme": "dark",
            "autosize": true,
            "showVolume": false,
            "showMA": false,
            "hideDateRanges": false,
            "hideMarketStatus": true,
            "hideSymbolLogo": false,
            "scalePosition": "right",
            "scaleMode": "Normal",
            "fontFamily": "-apple-system, BlinkMacSystemFont, Trebuchet MS, Roboto, Ubuntu, sans-serif",
            "fontSize": "10",
            "noTimeScale": false,
            "valuesTracking": "1",
            "changeMode": "price-and-percent",
            "chartType": "area",
            "maLineColor": "#2962FF",
            "maLineWidth": 1,
            "maLength": 9,
            "lineWidth": 2,
            "lineType": 0,
            "dateRanges": [
              "1d|1",
              "1m|30",
              "3m|60",
              "12m|1D",
              "60m|1W",
              "all|1M"
            ]
          }`;

  if (!_.isEmpty(container.current.children)) {
    container.current.innerHTML = '';
  }

  container.current.appendChild(script);
};

const onClickEvent =
  (div: number, setSelectedIdx: React.Dispatch<React.SetStateAction<number>>) =>
  (e: any) => {
    e.preventDefault();
    setSelectedIdx(div);
  };

export const TradingViewChart = () => {
  const container = useRef(null);
  const [selectedIdx, setSelectedIdx] = useState<number>(0);

  useEffect(() => {
    const get = chartType.filter((e) => e.index === selectedIdx);
    if (!_.isEmpty(get)) {
      drawChart(container, get[0].label);
    }
  }, [container, selectedIdx]);

  return (
    <div className="chart">
      <div className="tab">
        <ul className="tab-nav">
          <li className={selectedIdx === 0 ? 'active' : ''}>
            <Link to="#/" onClick={onClickEvent(0, setSelectedIdx)}>
              지수
            </Link>
          </li>
          <li className={selectedIdx === 1 ? 'active' : ''}>
            <Link to="#/" onClick={onClickEvent(1, setSelectedIdx)}>
              주식
            </Link>
          </li>
          {/* <li>
            <Link to="#/">ETF</Link>
          </li> */}
          <li className={selectedIdx === 2 ? 'active' : ''}>
            <Link to="#/" onClick={onClickEvent(2, setSelectedIdx)}>
              크립토
            </Link>
          </li>
          <li className={selectedIdx === 3 ? 'active' : ''}>
            <Link to="#/" onClick={onClickEvent(3, setSelectedIdx)}>
              외환
            </Link>
          </li>
          <li className={selectedIdx === 4 ? 'active' : ''}>
            <Link to="#/" onClick={onClickEvent(4, setSelectedIdx)}>
              선물
            </Link>
          </li>
        </ul>
        <div className="tab-content">
          <div style={{ height: '100%', width: '100%' }}>
            <div
              className="tradingview-widget-container"
              ref={container}
              style={{ height: '100%', width: '100%' }}
            >
              <div
                className="tradingview-widget-container__widget"
                style={{ height: '100%', width: '100%' }}
              ></div>
            </div>
          </div>
        </div>
      </div>
      {/* .tab end */}
    </div>
  );
};

const crypto = `
            [
              "BINANCE:AAVEUSDT|1D"
            ],
            [
              "BYBIT:ADAUSDT|1D"
            ],
            [
              "BINANCE:ALICEUSDT|1D"
            ],
            [
              "BINANCE:ARUSDT|1D"
            ],
            [
              "BINANCE:AVAXUSDT|1D"
            ],
            [
              "BYBIT:BNBUSDT|1D"
            ],
            [
              "BINANCE:BTCUSDT|1D"
            ],
            [
              "BINANCE:CELRUSDT|1D"
            ],
            [
              "BINANCE:DASHUSDT|1D"
            ],
            [
              "BINANCE:DOTUSDT|1D"
            ],
            [
              "BINANCE:EGLDUSDT|1D"
            ],
            [
              "BINANCE:EOSUSDT|1D"
            ],
            [
              "BINANCE:ETHBTC|1D"
            ],
            [
              "BINANCE:ETHUSDT|1D"
            ],
            [
              "BINANCE:FILUSDT|1D"
            ],
            [
              "BINANCE:GALAUSDT|1D"
            ],
            [
              "BINANCE:HBARUSDT|1D"
            ],
            [
              "BINANCE:ICPUSDT|1D"
            ],
            [
              "BINANCE:LTCUSDT|1D"
            ],
            [
              "BINANCE:MATICUSDT|1D"
            ],
            [
              "BINANCE:NEARUSDT|1D"
            ],
            [
              "BINANCE:ONEUSDT|1D"
            ],
            [
              "BINANCE:RSRUSDT|1D"
            ],
            [
              "BINANCE:RUNEUSDT|1D"
            ],
            [
              "BINANCE:SANDUSDT|1D"
            ],
            [
              "BINANCE:UNIUSDT|1D"
            ],
            [
              "BINANCE:VETUSDT|1D"
            ],
            [
              "KUCOIN:XMRUSDT|1D"
            ],
            [
              "BINANCE:XRPUSDT|1D"
            ]
`;
const exchange = `
    [
      "FX:AUDUSD|1D"
    ],
    [
      "FX:EURUSD|1D"
    ],
    [
      "FX:GBPUSD|1D"
    ],
    [
      "OANDA:USDCAD|1D"
    ],
    [
      "FX:USDCHF|1D"
    ],
    [
      "FX:USDJPY|1D"
    ],
    [
      "OANDA:NZDUSD|1D"
    ],
    [
      "OANDA:USDTHB|1D"
    ],
    [
      "FX:USDTRY|1D"
    ]
`;

const prod = `
    [
      "CAPITALCOM:ALUMINUM|1D"
    ],
    [
      "BLACKBULL:BRENT|1D"
    ],
    [
      "NYMEX:CL1!|1D"
    ],
    [
      "PEPPERSTONE:COCOA|1D"
    ],
    [
      "PEPPERSTONE:COFFEE|1D"
    ],
    [
      "CAPITALCOM:COPPER|1D"
    ],
    [
      "PEPPERSTONE:NICKEL|1D"
    ],
    [
      "CAPITALCOM:PALLADIUM|1D"
    ],
    [
      "CAPITALCOM:PLATINUM|1D"
    ],
    [
      "NYMEX:RB1!|1D"
    ],
    [
      "PEPPERSTONE:SUGAR|1D"
    ],
    [
      "FXOPEN:XTIUSD|1D"
    ],
    [
      "PEPPERSTONE:ZINC|1D"
    ],
    [
      "FOREXCOM:CORN|1D"
    ],
    [
      "FOREXCOM:SOYBEAN|1D"
    ],
    [
      "PEPPERSTONE:WHEAT|1D"
    ],
    [
      "OANDA:XAUUSD|1D"
    ],
    [
      "OANDA:XAGUSD|1D"
    ]
`;

const index = `            [
              "NASDAQ:NDX|1D"
            ],
            [
              "XETR:DAX|1D"
            ],
            [
              "SSE:513030|1D"
            ],
            [
              "SPREADEX:FTSE|1D"
            ],
            [
              "TRADENATION:IBEX|1D"
            ],
            [
              "SPREADEX:RUSS_US|1D"
            ],
            [
              "FINRA:SPMB_SHORT_VOLUME|1D"
            ],
            [
              "FINRA:SPIB_SHORT_VOLUME|1D"
            ],
            [
              "FINRA:USDX_SHORT_VOLUME|1D"
            ],
            [
              "PEPPERSTONE:USDX|1D"
            ]`;
const stock = `
            [
              "NASDAQ:QQQ|1D"
            ],
            [
              "SPY|1D"
            ],
            [
              "AMEX:DIA|1D"
            ],
            [
              "NASDAQ:TSLA|1D"
            ],
            [
              "NASDAQ:AMZN|1D"
            ],
            [
              "NASDAQ:AAPL|1D"
            ],
            [
              "NYSE:DIS|1D"
            ],
            [
              "NASDAQ:EBAY|1D"
            ],
            [
              "NASDAQ:META|1D"
            ],
            [
              "NYSE:IBM|1D"
            ],
            [
              "NASDAQ:INTC|1D"
            ],
            [
              "NYSE:MANU|1D"
            ],
            [
              "NASDAQ:MU|1D"
            ],
            [
              "NASDAQ:MSFT|1D"
            ],
            [
              "NASDAQ:MSTR|1D"
            ],
            [
              "NASDAQ:MTSI|1D"
            ],
            [
              "NASDAQ:NFLX|1D"
            ],
            [
              "NYSE:NKE|1D"
            ],
            [
              "NASDAQ:NVDA|1D"
            ],
            [
              "NYSE:ORCL|1D"
            ],
            [
              "NASDAQ:PDD|1D"
            ],
            [
              "NASDAQ:PEP|1D"
            ],
            [
              "NYSE:PG|1D"
            ],
            [
              "NYSE:PM|1D"
            ],
            [
              "NASDAQ:QCOM|1D"
            ],
            [
              "NASDAQ:REGN|1D"
            ],
            [
              "NYSE:RL|1D"
            ],
            [
              "NYSE:SHAK|1D"
            ],
            [
              "NYSE:SHEL|1D"
            ],
            [
              "AMEX:SPXU|1D"
            ],
            [
              "NASDAQ:SBUX|1D"
            ],
            [
              "NYSE:TEVA|1D"
            ],
            [
              "NASDAQ:TXN|1D"
            ],
            [
              "NYSE:TM|1D"
            ],
            [
              "TSX:TSX|1D"
            ],
            [
              "NYSE:UBER|1D"
            ],
            [
              "AMEX:USO|1D"
            ],
            [
              "NYSE:VLO|1D"
            ],
            [
              "NYSE:VVV|1D"
            ],
            [
              "NASDAQ:WDC|1D"
            ],
            [
              "AMEX:XLE|1D"
            ],
            [
              "AMEX:XLF|1D"
            ],
            [
              "SWB:19YA|1D"
            ],
            [
              "XETR:ADS|1D"
            ],
            [
              "GETTEX:AXA|1D"
            ],
            [
              "NASDAQ:AZN|1D"
            ],
            [
              "NYSE:BA|1D"
            ],
            [
              "NYSE:BBVA|1D"
            ],
            [
              "MIL:1BEI|1D"
            ],
            [
              "XETR:BMW|1D"
            ],
            [
              "TASE:BRND|1D"
            ],
            [
              "MIL:BZU|1D"
            ],
            [
              "FWB:DJD|1D"
            ],
            [
              "NYSE:HEI|1D"
            ],
            [
              "MIL:1HOT|1D"
            ],
            [
              "NYSE:HSBC|1D"
            ],
            [
              "NASDAQ:III|1D"
            ],
            [
              "TSXV:KLM|1D"
            ],
            [
              "OMXHEX:LINDEX|1D"
            ],
            [
              "OTC:LVMHF|1D"
            ],
            [
              "MIL:MONC|1D"
            ],
            [
              "PSECZ:PKO|1D"
            ],
            [
              "MIL:PRY|1D"
            ],
            [
              "MIL:RACE|1D"
            ],
            [
              "MIL:1RNO|1D"
            ],
            [
              "XETR:RRU|1D"
            ],
            [
              "NYSE:SAP|1D"
            ],
            [
              "XETR:GOB|1D"
            ],
            [
              "XETR:SIE|1D"
            ],
            [
              "IDX:SMSM|1D"
            ],
            [
              "BCS:UNESPA|1D"
            ],
            [
              "PSECZ:VOLVB|1D"
            ],
            [
              "BIST:GARAN|1D"
            ],
            [
              "BIST:KCHOL|1D"
            ],
            [
              "BIST:THYAO|1D"
            ],
            [
              "ASX:COH|1D"
            ],
            [
              "GETTEX:TDK|1D"
            ]
`;
const chartType = [
  { index: 0, label: 'idx', text: index },
  { index: 1, label: 'st', text: stock },
  { index: 2, label: 'ct', text: crypto },
  { index: 3, label: 'ex', text: exchange },
  { index: 4, label: 'prd', text: prod },
];
