import { useEffect } from 'react';

export const News = ({ widgetId }: { widgetId: string }) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src =
      'https://s3.tradingview.com/external-embedding/embed-widget-timeline.js';
    script.async = true;
    script.innerHTML = JSON.stringify({
      feedMode: 'all_symbols',
      isTransparent: false,
      displayMode: 'compact',
      width: '100%',
      height: '100%',
      colorTheme: 'dark',
      locale: 'en',
    });

    const container = document.getElementsByClassName(
      `tradingview-widget-container__widget-${widgetId}`
    )[0];
    container.appendChild(script);

    return () => {
      // container.removeChild(script);
    };
  }, [widgetId]);

  return (
    <div
      className={`tradingview-widget-container tradingview-widget-container-${widgetId}`}
      style={{ width: '100%', minHeight: '500px' }}
    >
      <div
        className={`tradingview-widget-container__widget tradingview-widget-container__widget-${widgetId}`}
        style={{ width: '100%', minHeight: '500px' }}
      ></div>
    </div>
  );
};
export default News;
