/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosResponse } from 'axios';
import _ from 'lodash';
import { config } from '../../config/config';

// 기본 axios 설정
const api = axios.create({
  baseURL: config.api.protocol + '://' + config.api.host + config.api.prefix,
  maxRedirects: 0,
  headers: {
    'Content-Type': 'application/json',
  },
});

// 요청 인터셉터 설정
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('setrade_token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    console.log(token);
    return config;
  },
  (error) => Promise.reject(error)
);

// 응답 인터셉터 설정
api.interceptors.response.use(
  (response) => {
    const refreshedToken = response.headers['Authorization'];

    if (!_.isEmpty(refreshedToken) && _.startsWith(refreshedToken, 'Bearer ')) {
      localStorage.setItem(
        'setrade_token',
        refreshedToken.replace('Bearer ', '')
      );
    }
    return response;
  },
  (error) => {
    console.log(error.response);
    if (error.response?.status === 401) {
      localStorage.removeItem('setrade_token');
      localStorage.removeItem('setrade_session');
      window.location = `${process.env.PUBLIC_URL}/signin` as
        | Location
        | (string & Location);
    }
    // Promise.reject(error);
  }
);

/**
 * 응답에 토큰이 있는 경우는 리코일에 갱신해준다.
 * @param response
 */
export const catchRefreshedToken = async (
  response: AxiosResponse<any, any>
) => {
  const resultObj = response?.data;

  //응답이 존재하는 경우는
  if (resultObj) {
    //응답 객체에서 accessToken를 추출하여 리코일에 세팅
    if (!_.isEmpty(resultObj.token)) {
      localStorage.setItem('setrade_token', resultObj.token);
    }

    return response?.data?.result;
  }

  return resultObj;
};

export default api;
