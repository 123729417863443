/* eslint-disable @typescript-eslint/no-explicit-any */
import DOMPurify from 'dompurify';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
// import '../../assets/css/boardview.css';
// import '../../assets/css/common.css';
// import '../../assets/css/responsive.css';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import commentUser from '../../assets/img/comment-user.png';
import listPng from '../../assets/img/list.png';
import mThumbDown from '../../assets/img/m-thumb-down.svg';
import mThumbUp from '../../assets/img/m-thumb-up.svg';
import siren from '../../assets/img/siren.svg';
import thumbDown from '../../assets/img/thumb-down-outline.svg';
import thumbUp from '../../assets/img/thumb-up-outline.svg';
import viewPng from '../../assets/img/view.png';
import {
  addComment,
  delComment,
  dislikeComment,
  getBoardById,
  getComment,
  incrementDisLikeCnt,
  incrementLikeCnt,
  likeComment,
  reportBoard,
} from '../../common/api/board';
import {
  BoardContentDataType,
  CommentContentDataType,
} from '../../common/data/data-type';
import { ComTypeInfo } from './CommunityBoard';

export const BoardView = () => {
  const { boardId } = useParams();
  const navigate = useNavigate();
  const [board, setBoard] = useState<BoardContentDataType | undefined>(
    undefined
  );
  // const [comments, setComments] = useState<CommentContentDataType[]>([]);
  const [newComment, setNewComment] = useState<any>('');
  const queryClient = useQueryClient();
  const sessionStr = localStorage.getItem('setrade_session');
  const session = !_.isEmpty(sessionStr)
    ? JSON.parse(sessionStr as string)
    : undefined;

  useEffect(() => {
    const fetchBoard = async () => {
      const response = await getBoardById(boardId as string);
      setBoard(response);
    };

    fetchBoard();
  }, [boardId]);

  const { data: comments } = useQuery<CommentContentDataType[], Error>(
    'getCommentList',
    () => getComment(boardId!),
    {
      enabled: boardId !== null && boardId !== undefined,
    }
  );

  const excuteComment = async (input: CommentContentDataType) => {
    if (input.apiType === 'ADD') {
      return await addComment(input);
    }
    if (input.apiType === 'DEL') {
      return await delComment(input);
    }
    if (input.apiType === 'LIKE') {
      return await likeComment(input);
    }
    if (input.apiType === 'DISLIKE') {
      return await dislikeComment(input);
    }
  };

  const { mutate } = useMutation(excuteComment, {
    onSuccess: () => {
      queryClient.invalidateQueries('getCommentList');
      setNewComment('');
    },
  });

  const handleLike = async () => {
    await incrementLikeCnt(boardId as string);
    setBoard((prevBoard) => ({
      ...prevBoard,
      likeCnt: String(Number(prevBoard?.likeCnt ?? 0) + 1),
    }));
  };

  const handleDislike = async () => {
    await incrementDisLikeCnt(boardId as string);
    setBoard((prevBoard) => ({
      ...prevBoard,
      dislikeCnt: String(Number(prevBoard?.dislikeCnt ?? 0) + 1),
    }));
  };

  const handleReport = async () => {
    await reportBoard(boardId as string);
    alert('신고가 접수되었습니다.');
  };

  const handleAddComment = async () => {
    if (newComment.trim() === '') return;
    mutate({ apiType: 'ADD', boardId, commentContent: newComment });
  };

  if (!board) return <div>Loading...</div>;

  return (
    <main className="main">
      <section className="board">
        <div className="inner">
          <div className="title">
            <h3>
              [
              {
                ComTypeInfo.filter((e) => e.comType === board.boardType)[0]
                  ?.communityTitle
              }
              ] {board.boardTitle}
            </h3>
            <div className="sub">
              <div className="board-user">
                <img src={commentUser} alt="" />
                <span>{board.userName}</span>
              </div>
              <div className="board-info">
                <div>
                  <p>
                    작성일 <span>{board.createdAt}</span>
                  </p>
                </div>
                <div>
                  <img src={viewPng} alt="" /> <span>{board.viewCnt}</span>
                </div>
                <div>
                  <img src={mThumbUp} alt="" /> <span>{board.likeCnt}</span>
                </div>
                <div>
                  <img src={mThumbDown} alt="" />{' '}
                  <span>{board.dislikeCnt}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="content">
            {/* <div className="view-content">{board.boardContent}</div> */}
            <div
              className="view-content"
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(board.boardContent ?? ''),
              }}
            />
            <div className="thumbs-area">
              <ul className="thumbs-list">
                <li>
                  <span className="span-red">{board.likeCnt}</span>
                </li>
                <li>
                  <img src={thumbUp} alt="" onClick={handleLike} />
                </li>
                <li>
                  <img
                    className="thumbs-down"
                    src={thumbDown}
                    alt=""
                    onClick={handleDislike}
                  />
                </li>
                <li>
                  <span>{board.dislikeCnt}</span>
                </li>
              </ul>
              <ul className="sub-list">
                <li
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  <img src={listPng} alt="" />
                  <span>목록으로</span>
                </li>
                <li onClick={handleReport}>
                  <img src={siren} alt="" />
                  <span>신고하기</span>
                </li>
                {board.userId === session?.userId && (
                  <li
                    onClick={() => {
                      navigate('/write-content', {
                        state: {
                          title: ComTypeInfo.filter(
                            (e) => e.comType === board.boardType
                          )[0]?.communityTitle,
                          boardType: board.boardType,
                        },
                      });
                    }}
                  >
                    <img src={siren} alt="" />
                    <span>수정하기</span>
                  </li>
                )}
              </ul>
            </div>
          </div>
          <Comments
            comments={comments}
            newComment={newComment}
            setNewComment={setNewComment}
            handleAddComment={handleAddComment}
            handler={mutate}
          />
        </div>
      </section>
    </main>
  );
};

const Comments = ({
  comments,
  newComment,
  setNewComment,
  handleAddComment,
  handler,
}: {
  comments?: CommentContentDataType[];
  newComment: string;
  setNewComment: any;
  handleAddComment: any;
  handler: any;
}) => (
  <div className="comment">
    <div className="title">
      <h3>
        댓글 <span className="span-red">{comments?.length ?? 0}</span>
      </h3>
    </div>
    {comments?.map((comment) => (
      <CommentElement
        key={comment.commentId}
        comment={comment}
        handler={handler}
      />
    ))}
    <div className="add-comment">
      <input
        type="text"
        placeholder="댓글을 입력하세요"
        value={newComment}
        onChange={(e) => setNewComment(e.target.value)}
      />
      <button onClick={handleAddComment}>댓글 추가</button>
    </div>
  </div>
);

const CommentElement = ({
  comment,
  handler,
}: {
  comment: CommentContentDataType;
  handler: any;
}) => {
  const eventAdder = (type: string) => (e: any) => {
    e.preventDefault();
    handler({
      apiType: type,
      boardId: comment.boardId,
      commentId: comment.commentId,
    });
  };

  return (
    <div className="one-comment">
      <div className="comment-user">
        <div className="user-info">
          <figure>
            <img src={commentUser} alt="" />
          </figure>
          <div>
            <h4>{comment.createdByName}</h4>
            <span>{comment.createdAt}</span>
          </div>
        </div>
        <div className="thumb-area">
          {comment.isMyComment ? (
            <Link to={'#/'} onClick={eventAdder('DEL')}>
              답글삭제
            </Link>
          ) : null}
          <img
            className="point:cursor"
            onClick={eventAdder('LIKE')}
            src={thumbUp}
            alt=""
          />
          <span>{comment.likeCnt}</span>
          <img
            className="point:cursor"
            onClick={eventAdder('DISLIKE')}
            src={thumbDown}
            alt=""
          />
          <span>{comment.dislikeCnt}</span>
        </div>
      </div>
      <div className="comment-word">
        <p>{comment.commentContent}</p>
      </div>
    </div>
  );
};
