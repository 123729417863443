/* eslint-disable @typescript-eslint/no-explicit-any */
import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import '../../assets/css/resetpassword.css';
import { verifyAuthCodeByLoginIdAndCellNumber } from '../../common/api/auth';
import {
  resetPassword,
  sendSmsVerificationWithLoginId,
} from '../../common/api/user';
import { validatePhone } from '../../common/util/validator';

export const ResetPassword = () => {
  const [loginId, setLoginId] = useState('');
  const [phone, setPhone] = useState('');
  const [phoneSent, setPhoneSent] = useState(false);
  const [phoneError, setPhoneError] = useState('');
  const [phoneTimer, setPhoneTimer] = useState(0);
  const [phoneVerified, setPhoneVerified] = useState(false);

  useEffect(() => {
    let phoneInterval: any;
    if (phoneTimer > 0) {
      phoneInterval = setInterval(() => {
        setPhoneTimer((prev) => prev - 1);
      }, 1000);
    }
    return () => clearInterval(phoneInterval);
  }, [phoneTimer]);

  const handlePhoneAuth = async () => {
    if (!validatePhone(phone)) {
      setPhoneError('전화번호 형식이 잘못되었습니다.');
      return;
    }
    setPhoneError('');

    try {
      await sendSmsVerificationWithLoginId(loginId, phone);
      setPhoneSent(true);
      startPhoneTimer();
    } catch (error) {
      setPhoneError('회원정보를 찾을 수 없습니다.');
    }
  };

  const verifyPhoneCode = useCallback(
    async (e: any) => {
      const code = e.target.value;

      if (!phoneSent || _.isEmpty(code) || code.length() !== 6) {
        return;
      }

      try {
        await verifyAuthCodeByLoginIdAndCellNumber(loginId, phone, code);
        setPhoneVerified(true);
      } catch (error) {
        alert('인증번호 오류');
        setPhoneVerified(false);
      }
    },
    [phone, phoneSent, loginId, setPhoneVerified]
  );

  //인증완료처리를 확인하여 패스워드를 리셋해서 SMS로 보내준다.
  const handleResetPassword = useCallback(async () => {
    try {
      await resetPassword(loginId, phone);
      setPhoneVerified(true);
    } catch (error) {
      alert('인증번호 오류');
      setPhoneVerified(false);
    }

    alert('휴대전화로 초기화된 패스워드를 전송하였습니다.');
  }, [loginId, phone]);

  const startPhoneTimer = () => {
    setPhoneTimer(90);
    const interval = setInterval(() => {
      setPhoneTimer((prev) => {
        if (prev <= 1) {
          clearInterval(interval);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);
  };
  return (
    <main className="resetpassword-main">
      <section className="resetpassword-section">
        <div className="inner">
          <div className="title">
            <h3 className="service-name">
              <span className="set">SET</span>RADE COMMUNITY 비밀번호 초기화
            </h3>
          </div>
          <div className="content">
            <div className="content-inner">
              <div className="user-id-area">
                <input
                  type="text"
                  placeholder="아이디"
                  className="join-input"
                  value={loginId}
                  onChange={(e) => {
                    setPhoneSent(false);
                    setPhoneVerified(false);
                    setLoginId(e.target.value);
                  }}
                />
              </div>
              <div className="user-tel-area">
                <input
                  type="number"
                  placeholder="전화번호"
                  className="join-input"
                  value={phone}
                  onChange={(e) => {
                    setPhoneSent(false);
                    setPhoneVerified(false);
                    setPhone(e.target.value);
                  }}
                />
                <button
                  type="button"
                  className="cert-btn"
                  onClick={handlePhoneAuth}
                >
                  휴대전화 인증
                </button>
              </div>
              {phoneError && <small className="error-text">{phoneError}</small>}
              {phoneSent && (
                <div className="auth-code-area">
                  <input
                    type="text"
                    placeholder="인증번호"
                    className={`join-input ${phoneVerified ? 'success' : ''}`}
                    onChange={verifyPhoneCode}
                  />
                  <div className={`timer ${phoneVerified ? 'success' : ''}`}>
                    <span>
                      {phoneVerified
                        ? '인증 완료'
                        : phoneTimer > 0
                          ? `${Math.floor(phoneTimer / 60)}:${phoneTimer % 60 < 10 ? `0${phoneTimer % 60}` : phoneTimer % 60}`
                          : '인증시간 만료'}
                    </span>
                  </div>
                </div>
              )}
              <div className="join-btn-area">
                <button
                  type="button"
                  disabled={
                    phoneVerified && !_.isEmpty(phone) && !_.isEmpty(loginId)
                  }
                  className="join-btn"
                  onClick={handleResetPassword}
                >
                  패스워드 초기화
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};
