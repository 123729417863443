import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../assets/css/signin.css'; // 새로운 CSS 파일
import { login } from '../../common/api/auth';

export const SignIn = () => {
  const [loginId, setLoginId] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    try {
      const response = await login(loginId, password);
      console.log('Login successful:', response);
      // 로그인 성공 시 처리
      localStorage.setItem('setrade_token', response.token);
      localStorage.setItem(
        'setrade_session',
        JSON.stringify({
          userId: response.userId,
          loginId: response.loginId,
          userName: response.userName,
          level: response.level,
        })
      );

      navigate('/home', { replace: true });
    } catch (err) {
      console.log(err);
      setError('Invalid login credentials');
    }
  };

  return (
    <main className="signin-main">
      <section className="signin-section">
        <div className="inner">
          <div className="title">
            <h3 className="service-name">
              <span className="set">SET</span>RADE COMMUNITY
            </h3>
          </div>
          <div className="content">
            <form onSubmit={handleSubmit}>
              <div className="content-inner">
                <div className="user-id-area">
                  <input
                    type="text"
                    placeholder="아이디"
                    className="join-input"
                    value={loginId}
                    onChange={(e) => setLoginId(e.target.value)}
                  />
                </div>
                <div className="user-pw-area">
                  <input
                    type="password"
                    placeholder="비밀번호"
                    className="join-input"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                {error && <div className="error">{error}</div>}
                <div className="join-btn-area">
                  <button type="submit" className="join-btn">
                    로그인
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    </main>
  );
};
