import React, { useState } from 'react';
import { CommunityBoard } from '../board/CommunityBoard';
import { ChatRoomModal } from '../chat/ChatRoomModal';
import { ChatWaitRoomModal } from '../chat/ChatWaitRoomModal';
import { EcoCalendar } from './EcoCalendar';
import { LeftUpContents } from './LeftUpContents';
import { MobileWideChat } from './MobileWideChat';
import { SummaryChart } from './SummaryChart';
import { TradingViewChart } from './TradingViewChart';
import { WideChat } from './WideChat';

export const Main = () => {
  const [waitShow, setWaitShow] = useState<boolean>(false);
  const [chatShow, setChatShow] = useState<string | undefined>(undefined);

  return (
    <React.Fragment>
      <main className="main">
        <section className="content">
          <div className="left-content pc">
            {/* 좌측상단 - 로그인, 공지사항 */}
            <LeftUpContents />
            {/* 전체채팅 */}
            <WideChat
              waitShow={waitShow}
              setWaitShow={setWaitShow}
              setChatShow={setChatShow}
            />
            {/* 경제캘린더 */}
            <EcoCalendar />
          </div>

          <div className="main-content">
            <SummaryChart />
            <TradingViewChart />
            <MobileWideChat />
            <CommunityBoard />
            {/* <CommunityBoard comType="REAL" communityTitle="실시간 지표" /> */}
          </div>
        </section>
      </main>
      <ChatWaitRoomModal
        show={waitShow}
        setShow={setWaitShow}
        chatShow={chatShow}
        setChatShow={setChatShow}
      />
      <ChatRoomModal show={chatShow} setShow={setChatShow} />
    </React.Fragment>
  );
};
