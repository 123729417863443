// import '../assets/css/modal.css';

const AgreementPopup = ({
  content,
  onClose,
}: {
  content: string;
  onClose: () => void;
}) => (
  <div className="modal-overlay">
    <div className="modal-content">
      <h3>{content}</h3>
      <p>여기에 약관 내용을 표시합니다...</p>
      <button className="modal-close-btn" onClick={onClose}>
        닫기
      </button>
    </div>
  </div>
);

export default AgreementPopup;
