/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

const SessionCheck = () => {
  const [check, setCheck] = useState<any>(null);

  useEffect(() => {
    setCheck({ isLogin: localStorage.getItem('setrade_token') ? true : false });
  }, [setCheck]);

  if (!check) {
    return <div>...로딩중...</div>;
  }

  return check?.isLogin === true ? <Outlet /> : <Navigate to="/signin" />;
};

export default SessionCheck;
