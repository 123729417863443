/* eslint-disable @typescript-eslint/no-explicit-any */
import api from './api';

/**
 *  채팅 관련 API
 */
export const getChatRoomList = async () => {
  const response = await api.get('/channels/list');
  return response?.data;
};

export const getChatRoom = async (channelId: string) => {
  const response = await api.get(`/channels/${channelId}`);
  return response?.data;
};

export const joinChatRoom = async (channelId: string) => {
  const response = await api.get(`/channels/${channelId}`);
  return response?.data;
};

export const createChatRoom = async (chatRoomName: string) => {
  const response = await api.post('/channels/create', { name: chatRoomName });
  return response?.data;
};
