const pwdRex =
  /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,20}$/;
const nickRex = /^(?=.*[a-zA-Z0-9가-힣]).{2,10}$/;
const emailRex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
const cellNumberRex = /^\d{10,11}$/;
const userIdRex = /^[a-zA-Z0-9]{6,12}$/;

export const validateEmail = (value: string) => emailRex.test(value);
export const validatePhone = (value: string) => cellNumberRex.test(value);

export const validatePassword = (value: string) => pwdRex.test(value);
export const validateNickname = (value: string) => nickRex.test(value);
export const validateUserId = (value: string) => userIdRex.test(value);
