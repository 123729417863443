import { useEffect } from 'react';

export const SummaryChart = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src =
      'https://s3.tradingview.com/external-embedding/embed-widget-ticker-tape.js';
    script.async = true;
    script.innerHTML = JSON.stringify({
      symbols: [
        { description: '', proName: 'OANDA:XAUUSD' },
        { description: '', proName: 'NASDAQ:NDX' },
        { description: '', proName: 'OANDA:EURUSD' },
        { description: '', proName: 'OANDA:NZDUSD' },
        { description: '', proName: 'BINANCE:BTCUSDT' },
        { description: '', proName: 'KRX:KOSPI' },
        { description: '', proName: 'NASDAQ:TSLA' },
        { description: '', proName: 'NASDAQ:NVDA' },
        { description: '', proName: 'NASDAQ:NDX' },
      ],
      showSymbolLogo: true,
      isTransparent: false,
      displayMode: 'compact',
      colorTheme: 'dark',
      locale: 'en',
    });

    document.getElementById('tradingview-widget')?.appendChild(script);
  }, []);

  return (
    <div className="chart-summary">
      <div className="tradingview-widget-container">
        <div
          id="tradingview-widget"
          className="tradingview-widget-container__widget"
        ></div>
        <div className="tradingview-widget-copyright"></div>
      </div>
    </div>
  );
};
