const config = {
  api: {
    protocol: process.env.REACT_APP_ADMIN_API_PROTOCOL,
    host: process.env.REACT_APP_ADMIN_API_HOST,
    prefix: process.env.REACT_APP_ADMIN_API_PREFIX,
  },
};

console.log(config);
//환경 변수 익스포팅
export { config };
