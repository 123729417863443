/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { atom, useSetRecoilState } from 'recoil';
import logo from '../assets/img/logo.png';
import mbmenu from '../assets/img/mobile-menu.svg';
import { LeftUpContents } from '../pages/main/LeftUpContents';
export const boardRecoil = atom<number>({
  key: 'SETRADE_BOARD_RECOIL',
  default: 0,
});

const MobileMenu = ({ setShowMobileMenu }: { setShowMobileMenu: any }) => {
  const setBoardIndex = useSetRecoilState(boardRecoil);

  const onClickEvent = useCallback(
    (e: any, index: number) => {
      e.preventDefault();
      setBoardIndex(index);
    },
    [setBoardIndex]
  );

  return (
    <div className="modal-overlay">
      <nav className="mobile mobile-nav">
        <LeftUpContents
          isMobile={true}
          closeEvent={() => setShowMobileMenu(false)}
        />
        <ul className="gnb">
          <li>
            <Link
              to="#/"
              onClick={(e) => {
                onClickEvent(e, 0);
                setShowMobileMenu(false);
              }}
            >
              수익인증
            </Link>
          </li>
          <li>
            <Link
              to="#/"
              onClick={(e) => {
                onClickEvent(e, 1);
                setShowMobileMenu(false);
              }}
            >
              매매법공유
            </Link>
          </li>
          <li>
            <Link
              to="#/"
              onClick={(e) => {
                onClickEvent(e, 2);
                setShowMobileMenu(false);
              }}
            >
              실시간지표
            </Link>
          </li>
          <li>
            <Link
              to="#/"
              onClick={(e) => {
                onClickEvent(e, 3);
                setShowMobileMenu(false);
              }}
            >
              자유게시판
            </Link>
          </li>
          <li>
            <Link
              to="#/"
              onClick={(e) => {
                onClickEvent(e, 4);
                setShowMobileMenu(false);
              }}
            >
              유머게시판
            </Link>
          </li>
          <li>
            <Link
              to="#/"
              onClick={(e) => {
                onClickEvent(e, 5);
                setShowMobileMenu(false);
              }}
            >
              뉴스게시판
            </Link>
          </li>
          <li>
            <Link
              to="#/"
              onClick={(e) => {
                onClickEvent(e, 0);
                setShowMobileMenu(false);
              }}
            >
              고객센터
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export const Header = () => {
  const setBoardIndex = useSetRecoilState(boardRecoil);
  const [showMobileMenu, setShowMobileMenu] = useState<boolean>(false);
  const location = useLocation();
  const navigate = useNavigate();

  const onClickEvent = useCallback(
    (e: any, index: number) => {
      e.preventDefault();
      setBoardIndex(index);
    },
    [setBoardIndex]
  );

  useEffect(() => {
    // 컴포넌트가 활성화되면 body에 no-scroll 클래스 추가
    if (showMobileMenu) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }

    // 컴포넌트가 비활성화되거나 언마운트될 때 클래스 제거
    return () => {
      document.body.style.overflow = '';
    };
  }, [showMobileMenu]);

  useEffect(() => {
    if (showMobileMenu) {
      setShowMobileMenu(false);
    }
  }, [location]);
  return (
    <header className="header">
      <h1 onClick={() => navigate('/')} className="logo">
        <img src={logo} alt="로고이미지"></img>
        <span>SETRADE COMMUNITY</span>
      </h1>
      <nav className="nav pc">
        <ul className="gnb">
          <li>
            <Link to="#/" onClick={(e) => onClickEvent(e, 0)}>
              수익인증
            </Link>
          </li>
          <li>
            <Link to="#/" onClick={(e) => onClickEvent(e, 1)}>
              매매법공유
            </Link>
          </li>
          <li>
            <Link to="#/" onClick={(e) => onClickEvent(e, 2)}>
              실시간지표
            </Link>
          </li>
          <li>
            <Link to="#/" onClick={(e) => onClickEvent(e, 3)}>
              자유게시판
            </Link>
          </li>
          <li>
            <Link to="#/" onClick={(e) => onClickEvent(e, 4)}>
              유머게시판
            </Link>
          </li>
          <li>
            <Link to="#/" onClick={(e) => onClickEvent(e, 5)}>
              뉴스게시판
            </Link>
          </li>
          <li>
            <Link to="#/" onClick={(e) => onClickEvent(e, 6)}>
              고객센터
            </Link>
          </li>
        </ul>
      </nav>
      {/* .nav end */}
      <div
        className="mobile menu-btn"
        onClick={() => setShowMobileMenu(!showMobileMenu)}
      >
        <img src={mbmenu} alt="메뉴아이콘" />
      </div>

      {showMobileMenu ? (
        <MobileMenu setShowMobileMenu={setShowMobileMenu} />
      ) : null}
    </header>
  );
};
