/* eslint-disable @typescript-eslint/no-explicit-any */
import _ from 'lodash';
import { MouseEvent, useCallback, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import bulb from '../../assets/img/bulb.svg';
import close from '../../assets/img/close.svg';
import avatar from '../../assets/img/user-avatar.svg';
import { logout } from '../../common/api/auth';
export const LeftUpContents = ({
  isMobile,
  closeEvent,
}: {
  isMobile?: boolean;
  closeEvent?: any;
}) => {
  const [session, setSession] = useState<
    | {
        userId?: string;
        loginId?: string;
        userName?: string;
        level?: string;
      }
    | undefined
  >(undefined);

  useEffect(() => {
    const sessionStr = localStorage.getItem('setrade_session');
    setSession(
      !_.isEmpty(sessionStr) ? JSON.parse(sessionStr as string) : undefined
    );
  }, [setSession]);

  return (
    <div className="user-login">
      <div className="inner">
        <div className="alarm">
          {isMobile ? (
            <>
              <div>
                <figure>
                  <img src={bulb} alt="전구아이콘"></img>
                </figure>
                <span className="category">[공지]</span>
                <span className="title">서비스 오픈</span>
              </div>
              <figure onClick={closeEvent}>
                <img id="navClose" src={close} alt="닫기 버튼" />
              </figure>
            </>
          ) : (
            <>
              <figure>
                <img src={bulb} alt="전구아이콘"></img>
              </figure>
              <span className="category">[공지]</span>
              <span className="title">서비스 오픈</span>
            </>
          )}
        </div>
        {/* .alarm end */}
        {!session ? (
          <LoginParts />
        ) : (
          <ProfileParts
            userId={session.userId}
            userName={session.userName}
            loginId={session.loginId}
          />
        )}
      </div>
      {/* .inner end */}
    </div>
  );
};

const LoginParts = () => (
  <div className="login-area">
    <div className="login">
      <div className="info">모든 서비스 이용을 위해 로그인하세요</div>
      <Link to={'/signin'}>
        <div className="login-btn">
          <span className="span-red">SETRADE </span>
          <span>COMMUNITY</span>
          <span className="login-txt">로그인</span>
        </div>
      </Link>
    </div>
    {/* .login end */}
    <div className="login-sub">
      <ul className="list">
        <li>
          <Link to="/findid">아이디 찾기</Link>
        </li>
        <li>
          <Link to="/reset">비밀번호 초기화</Link>
        </li>
        <li>
          <Link to={'/signup'}>회원가입</Link>
        </li>
      </ul>
    </div>
    {/* .login-sub end */}
  </div>
);

const ProfileParts = ({
  userId,
  loginId,
  userName,
}: {
  userId?: string;
  loginId?: string;
  userName?: string;
}) => {
  const navigate = useNavigate();

  const callLogout = useCallback(
    async (e: MouseEvent<HTMLElement>) => {
      e.preventDefault();
      await logout(userId as string, loginId as string);
      localStorage.removeItem('setrade_session');
      localStorage.removeItem('setrade_token');

      navigate('/', { replace: true });
    },
    [userId, loginId, navigate]
  );

  return (
    <div className="user">
      <div className="avatar">
        <figure>
          <img src={avatar} alt="사용자 프로필 이미지"></img>
        </figure>
      </div>
      <div className="info">
        <p>
          <span>[</span>
          <span id={userId}>{userName}</span>
          <span>]</span>
          님, 안녕하세요.
        </p>
        <ul className="list">
          <li>
            <Link to={'/mypage'} state={userId}>
              내 정보
            </Link>
          </li>
          <li>
            <Link to={'#/'} onClick={(e) => callLogout(e)}>
              로그아웃
            </Link>
          </li>
          <li>
            <Link to={'/purchase'}>포인트</Link>
          </li>
        </ul>
      </div>
    </div>
  );
};
