import { Navigate, Route, Routes } from 'react-router-dom';
import SessionCheck from './common/hook/SessionCheck';
import ErrorPage from './components/ErrorPage';
import { PageLayout } from './components/PageLayour';
import { BoardView } from './pages/board/BoardView';
import { WriterContent } from './pages/board/WriterContent';
import { Main } from './pages/main/Main';
import { MyInfo } from './pages/myinfo/MyInfo';
import { PurchasePoints } from './pages/myinfo/PurchasePoints';
import { FindId } from './pages/signup/FindId';
import { ResetPassword } from './pages/signup/ResetPassword';
import { SignIn } from './pages/signup/SignIn';
import { SignUp } from './pages/signup/SignUp';

function Router() {
  console.log('init;');
  return (
    <Routes>
      {/* 공통 : 세션없이 접근하는 라우터*/}
      <Route path="/" element={<Navigate to="/home" />} />

      <Route path="/home" element={<PageLayout page={<Main />} />} />
      <Route
        path="/content/:boardId"
        element={<PageLayout page={<BoardView />} />}
      />
      <Route element={<SessionCheck />}>
        <Route
          path="/write-content"
          element={<PageLayout page={<WriterContent />} />}
        />
        <Route
          path="/purchase"
          element={<PageLayout page={<PurchasePoints />} />}
        />
        <Route path="/mypage" element={<PageLayout page={<MyInfo />} />} />
      </Route>
      <Route path="/signup" element={<PageLayout page={<SignUp />} />} />
      <Route path="/signin" element={<PageLayout page={<SignIn />} />} />
      <Route path="/findid" element={<PageLayout page={<FindId />} />} />
      <Route path="/reset" element={<PageLayout page={<ResetPassword />} />} />

      {/* 세션 도출 필요 */}

      <Route path="*" element={<ErrorPage />} />
    </Routes>
  );
}

export default Router;
