/* eslint-disable @typescript-eslint/no-explicit-any */
import { CommentContentDataType } from '../data/data-type';
import api from './api';

export const createPost = async (
  title: string,
  content: string,
  category: string
) => {
  const response = await api.post(`/boards`, {
    boardType: category,
    boardTitle: title,
    boardContent: content,
  });
  return response?.data;
};

export const getBoardContents = async (
  boardType: string,
  searchKeyword: string,
  page: number
) => {
  const response = await api.get(`/boards`, {
    params: {
      boardType,
      searchKeyword,
      page,
    },
  });
  return response?.data;
};

export const getBoardById = async (boardId: string) => {
  const response = await api.get(`/boards/${boardId}`);
  return response?.data;
};

export const incrementLikeCnt = async (boardId: string) => {
  await api.post(`/boards/${boardId}/like`);
};

export const incrementDisLikeCnt = async (boardId: string) => {
  await api.post(`/boards/${boardId}/dislike`);
};

export const reportBoard = async (boardId: string) => {
  await api.post(`/boards/${boardId}/report`);
};

export const getComment = async (boardId: string) => {
  const response = await api.get(`/boards/${boardId}/comment`);
  return response?.data;
};

export const addComment = async (commentContent: CommentContentDataType) => {
  const response = await api.post(`/boards/${commentContent.boardId}/comment`, {
    ...commentContent,
  });
  return response?.data;
};

export const delComment = async (commentContent: CommentContentDataType) => {
  const response = await api.delete(
    `/boards/${commentContent.boardId}/comment/${commentContent.commentId}`
  );
  return response?.data;
};

export const likeComment = async (commentContent: CommentContentDataType) => {
  const response = await api.post(
    `/boards/${commentContent.boardId}/comment/${commentContent.commentId}/like`
  );
  return response?.data;
};

export const dislikeComment = async (
  commentContent: CommentContentDataType
) => {
  const response = await api.post(
    `/boards/${commentContent.boardId}/comment/${commentContent.commentId}/dislike`
  );
  return response?.data;
};
