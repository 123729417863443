export const EcoCalendar = () => {
  return (
    <div className="calendar">
      <iframe
        title={'ECO_CALENDAR'}
        src="https://sslecal2.investing.com?columns=exc_flags,exc_currency,exc_importance,exc_actual,exc_forecast,exc_previous&features=datepicker,timezone&countries=110,43,17,42,5,178,32,12,26,36,4,72,10,14,48,35,37,6,122,41,22,11,25,39&calType=day&timeZone=88&lang=18"
        width={'100%'}
        height={'100%'}
        allowTransparency={true}
      ></iframe>
    </div>
  );
};
