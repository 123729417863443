import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import RecoilNexus from 'recoil-nexus';
import Router from './Router';

const client = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
  return (
    <QueryClientProvider client={client}>
      <RecoilRoot>
        <RecoilNexus />
        {/* <BrowserRouter basename={process.env.PUBLIC_URL}> */}
        <BrowserRouter basename="/">
          <Router />
        </BrowserRouter>
        {/* <ConfigProvider theme={CustomTheme}>
        <GlobalStyles />
      </ConfigProvider> */}
      </RecoilRoot>
      {/* <ReactQueryDevtools /> */}
    </QueryClientProvider>
  );
}

export default App;
