import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../assets/css/myinfo.css';
import avatar from '../../assets/img/user-avatar.svg';
import { getUserInfo, updateUserInfo } from '../../common/api/user';
import { UserDataType } from '../../common/data/data-type';
import {
  validateNickname,
  validatePassword,
} from '../../common/util/validator';
import ConfirmModal from '../../components/ConfirmModal';
import OverlayLoader from '../../components/OverlayLoader';
import { showToast } from '../../components/Toast';

export const MyInfo = () => {
  const navigate = useNavigate();
  const [isSaving, setIsSaving] = useState(false);
  const [userData, setUserData] = useState({
    userId: '',
    loginId: '',
    email: '',
    cellNumber: '',
    userName: '',
    level: '',
  });

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [nickname, setNickname] = useState('');
  const [errors, setErrors] = useState({
    password: '',
    confirmPassword: '',
    nickname: '',
  });
  const [isModalOpen, setIsModalOpen] = useState(false);

  const fetchUserInfo = useCallback(async () => {
    try {
      const sessionStr = localStorage.getItem('setrade_session');
      const jsonSession = !_.isEmpty(sessionStr)
        ? JSON.parse(sessionStr as string)
        : undefined;
      const userId = jsonSession?.userId;

      if (userId) {
        const response = await getUserInfo(userId);
        setUserData(response);
        setNickname(response.userName);
      }
    } catch (error) {
      console.error('Failed to fetch user info:', error);
    }
  }, []);

  useEffect(() => {
    // 사용자 정보를 로딩하는 API 호출
    fetchUserInfo();
  }, [fetchUserInfo]);

  const handleSave = () => {
    let valid = true;
    const newErrors = {
      password: '',
      confirmPassword: '',
      nickname: '',
    };

    if (password && !validatePassword(password)) {
      newErrors.password =
        '비밀번호는 8-20자의 영문자, 숫자, 특수문자를 포함해야 합니다.';
      valid = false;
    }

    if (password && password !== confirmPassword) {
      newErrors.confirmPassword = '비밀번호가 일치하지 않습니다.';
      valid = false;
    }

    if (!validateNickname(nickname)) {
      newErrors.nickname =
        '닉네임은 한글 5자 또는 영어 10자 이하로 입력해야 합니다.';
      valid = false;
    }

    setErrors(newErrors);

    if (valid) {
      if (password || nickname !== userData.userName) {
        setIsModalOpen(true); // 비밀번호 또는 닉네임이 변경되었을 때 모달을 띄움
      } else {
        // 바로 저장 로직
        console.log('회원정보 저장 로직 추가');
      }
    }
  };

  const handleConfirm = async () => {
    setIsModalOpen(false);
    setIsSaving(true);
    try {
      const subObj: UserDataType = {};
      subObj.userId = userData.userId;
      if (password) {
        subObj.pwd = String(password);
      }

      if (nickname) {
        subObj.userName = String(nickname);
      }

      await updateUserInfo(subObj);
      showToast('게시물 등록 성공'); // 토스트 알림 표시

      //세션 업데이트
      const sessionStr = localStorage.getItem('setrade_session');
      const jsonSession = !_.isEmpty(sessionStr)
        ? JSON.parse(sessionStr as string)
        : undefined;
      jsonSession.userName = subObj.userName;

      localStorage.setItem('setrade_session', JSON.stringify(jsonSession));

      setTimeout(() => {
        setIsSaving(false);
        navigate(-1); // 예시로 게시판 목록 페이지로 이동
      }, 3000); // 3초 후에 이동
    } catch (error) {
      showToast('게시물 등록 실패'); // 토스트 알림 표시
      console.error('Failed to create post:', error);
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false); // 모달 닫기
    navigate(-1); // 취소 버튼을 누르면 홈으로 이동
  };

  return (
    <>
      <main className="main">
        <section className="join mypage">
          <div className="inner">
            <div className="title">
              <h3>내 정보 수정</h3>
            </div>
            <div className="content">
              <div className="user-id-area">
                <h4>아이디</h4>
                <input
                  type="text"
                  placeholder="아이디"
                  className="join-input"
                  value={userData.loginId}
                  disabled
                />
              </div>
              <div className="user-email-area">
                <h4>이메일</h4>
                <input
                  type="text"
                  placeholder="이메일"
                  className="join-input"
                  value={userData.email}
                  disabled
                />
              </div>
              <div className="user-tel-area">
                <h4>전화번호</h4>
                <input
                  type="text"
                  placeholder="전화번호"
                  className="join-input"
                  value={userData.cellNumber}
                  disabled
                />
              </div>
              <div className="user-profile-area">
                <h4>프로필 이미지</h4>
                <div className="user-profile-all">
                  <div className="user-profile-img">
                    <figure>
                      <img src={avatar} alt="프로필 임시이미지" />
                    </figure>
                    <button type="button" className="change-btn">
                      이미지 변경하기
                    </button>
                  </div>
                  <div className="user-profile-info">
                    <p>
                      * 등록할 수 있는 사진의 크기는 최소 150 x 150 픽셀이상
                      최대 용량은 1MB입니다.
                    </p>
                    <p>
                      * 등록시 네임카드, 게시판의 댓글부분, 로그인 박스 등에
                      표시됩니다.
                    </p>
                    <p>
                      * 선정적, 사회정치적으로 문제가 되는 이미지는
                      등록금지이며, 예고없이 삭제될 수 있습니다.
                    </p>
                  </div>
                </div>
              </div>
              <div className="user-pw-area">
                <h4>비밀번호 변경</h4>
                <div className="user-pw-input">
                  <input
                    type="password"
                    placeholder="비밀번호"
                    className={`join-input ${errors.password ? 'error' : ''}`}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  {errors.password && (
                    <small className="error-text">{errors.password}</small>
                  )}
                  <input
                    type="password"
                    placeholder="비밀번호 확인"
                    className={`join-input ${errors.confirmPassword ? 'error' : ''}`}
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                  {errors.confirmPassword && (
                    <small className="error-text">
                      {errors.confirmPassword}
                    </small>
                  )}
                  <div className="pw-txt">
                    <p>* 비밀번호는 8 ~ 20 자리로 입력해주세요.</p>
                    <p>
                      * 비밀번호는 문자, 숫자, 특수문자 중 2가지 이상의 조합으로
                      입력해주세요.
                    </p>
                  </div>
                </div>
              </div>
              <div className="user-nickname-area">
                <h4>닉네임</h4>
                <input
                  type="text"
                  placeholder="닉네임"
                  className={`join-input ${errors.nickname ? 'error' : ''}`}
                  value={nickname}
                  onChange={(e) => setNickname(e.target.value)}
                />
                {errors.nickname && (
                  <small className="error-text">{errors.nickname}</small>
                )}
              </div>
              <div className="join-btn-area">
                <button type="button" className="join-btn" onClick={handleSave}>
                  회원정보 수정
                </button>
                <button
                  type="button"
                  className="cancel-btn"
                  onClick={handleCancel}
                >
                  취소
                </button>
              </div>
            </div>
          </div>
        </section>
        {isSaving && <OverlayLoader />}
      </main>
      <ConfirmModal
        isOpen={isModalOpen}
        message="회원정보를 변경하시겠습니까?" // 메시지 전달
        onConfirm={handleConfirm}
        onCancel={() => setIsModalOpen(false)}
      />
    </>
  );
};
