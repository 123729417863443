/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  ChatRoomDetailData,
  ChatUserInfo,
  UserPickInfo,
} from '../../common/data/data-type';

import _ from 'lodash';
import { useQuery } from 'react-query';
import SockJS from 'sockjs-client';
import Stomp from 'stompjs';
import chaUser from '../../assets/img/chat-user-temp.png';
import chat from '../../assets/img/chat.svg';
import close from '../../assets/img/close.svg';
import crown from '../../assets/img/mdi_crown.svg';
import chatUp from '../../assets/img/mingcute_up-fill.svg';
import avatar from '../../assets/img/user-avatar-red.svg';
import { getChatRoom } from '../../common/api/chat';
import { config } from '../../config/config';

export const ChatRoomModal = ({
  show,
  setShow,
}: {
  show: string | undefined;
  setShow: any;
}) => {
  const [messages, setMessages] = useState<any[]>([]);
  const [message, setMessage] = useState<string>('');
  const [userInfo, setUserInfo] = useState<
    { userName: string; isOwner: boolean } | undefined
  >(undefined);
  const [stompClient, setStompClient] = useState<Stomp.Client | null>(null);
  const { data: chatRoom, isSuccess } = useQuery<ChatRoomDetailData, Error>(
    'getChatRoom',
    () => getChatRoom(show!),
    { enabled: !!show && !_.isEmpty(show) }
  );

  const onClose = useCallback(() => {
    document.body.style.overflow = 'auto';
    setShow(false);
  }, [setShow]);

  useEffect(() => {
    //채팅방정보 조회
    if (isSuccess && chatRoom) {
      //채팅방 참가

      //내 채팅방인지 아닌지.
      const sessionStr = localStorage.getItem('setrade_session');
      if (_.isEmpty(sessionStr)) {
        setUserInfo({
          isOwner: false,
          userName: 'Guest',
        });
      } else {
        const seesionInfo = JSON.parse(sessionStr as string);
        const userId = seesionInfo.userId;
        setUserInfo({
          isOwner: chatRoom.createdBy === userId,
          userName: `${seesionInfo?.userName ?? 'Guest'} ${seesionInfo?.userName !== 'Guest' ? `( Lv.${seesionInfo.level ?? 0} )` : ''} `,
        });
      }
    }
  }, [chatRoom, isSuccess]);

  useEffect(() => {
    if (chatRoom) {
      const socket = new SockJS(
        `${config.api.protocol}://${config.api.host}/ws`
      );
      const client = Stomp.over(socket);

      client.connect({}, () => {
        client.subscribe(`/topic/${chatRoom?.id}/addmessage`, (msg) => {
          const newMessage = JSON.parse(msg.body);
          if (
            newMessage &&
            _.trim(newMessage.content) &&
            _.trim(newMessage.content) !== ''
          ) {
            setMessages((prevMessages) => [
              ...prevMessages,
              JSON.parse(msg.body),
            ]);
          }
        });

        client.subscribe(`/topic/${chatRoom?.id}/addmember`, (msg) => {
          const newMessage = JSON.parse(msg.body);
          if (_.trim(newMessage) && _.trim(newMessage) !== '') {
            const el = [...messages];
            el.push(`${newMessage.sender} 님이 채팅에 참가하셨습니다.`);
            setMessages(el);
          }
        });

        client.subscribe(`/topic/${chatRoom?.id}/removemember`, (msg) => {
          const newMessage = JSON.parse(msg.body);
          if (_.trim(newMessage) && _.trim(newMessage) !== '') {
            const el = [...messages];
            el.push(`${newMessage.sender} 님이 나가셨습니다..`);
            setMessages(el);
          }
        });

        // client.subscribe('/topic/participants', (msg) => {
        //   setParticipants(JSON.parse(msg.body));
        // });

        const joinMessage = {
          content: `참가`,
          sender: userInfo?.userName,
          type: 'JOIN',
        };
        client.send(
          `/app/chat.addUser/${chatRoom?.id}`,
          {},
          JSON.stringify(joinMessage)
        );
      });
      setStompClient(client);
    }

    return () => {
      if (stompClient) {
        const joinMessage = {
          content: `퇴장`,
          sender: userInfo?.userName,
          type: 'LEAVE',
        };
        stompClient.send(
          `/app/chat.removeUser/${chatRoom?.id}`,
          {},
          JSON.stringify(joinMessage)
        );
        stompClient.disconnect(() => {});
      }

      document.body.style.overflow = 'auto';
      setStompClient(null);
      setShow(undefined);
    };
  }, [chatRoom]);

  const sendMessage = () => {
    if (
      chatRoom?.id &&
      stompClient &&
      _.trim(message) &&
      _.trim(message) !== ''
    ) {
      const chatMessage = {
        sender: userInfo?.userName, // 로그인한 유저의 ID
        content: message,
        type: 'CHAT',
      };

      console.log(stompClient);
      console.log(`/app/chat.sendMessage/${chatRoom?.id}`);
      stompClient.send(
        `/app/chat.sendMessage/${chatRoom?.id}`,
        {},
        JSON.stringify(chatMessage)
      );
      setMessage('');
    }
  };

  return show && isSuccess && chatRoom !== undefined ? (
    <>
      <div onClick={onClose} className="modal-bg"></div>
      <div className="modal-chat-room" id="modal-chat-room">
        <div className="inner">
          <ChatHeader
            name={chatRoom.name ?? ''}
            createrName={chatRoom.createrName ?? ''}
            onClose={onClose}
          />
          <div className="content">
            <div className="chart-chat">
              {userInfo?.isOwner ? <ChartAreaOwnerMode /> : <ChartAreaUser />}
              <ChatLine
                messages={messages}
                sendMessage={sendMessage}
                message={message}
                setMessage={setMessage}
              />
            </div>
            <div className="right-content">
              <ChatStatus {...chatRoom} />
              {/* .status end */}
              <ChatPick
                userName={chatRoom.createrName!}
                picks={chatRoom.picks}
              />
              {/* .pick end */}
              <ChatUser ownerId={chatRoom.createdBy!} users={chatRoom.users} />
            </div>
          </div>
        </div>
      </div>
    </>
  ) : null;
};

const ChartAreaOwnerMode = () => (
  <div className="chart-area">
    <div className="chart-style__01">
      <ul className="list">
        <li className="active">
          <Link to={'#/'}>매수</Link>
        </li>
        <li>
          <Link to={'#/'}>매도</Link>
        </li>
        <li>
          <Link to={'#/'}>매수마감</Link>
        </li>
        <li>
          <Link to={'#/'}>매도마감</Link>
        </li>
      </ul>
      <div className="search chart-search">
        <input type="text" className="input-search" placeholder="종목 검색" />
      </div>
      <div className="search point-input">
        <input type="text" className="input-search" placeholder="투자 포인트" />
      </div>
    </div>
    <div className="modal-tab-content">
      <div>chart area1</div>
    </div>
  </div>
);

const ChartAreaUser = ({ picks }: ChatRoomDetailData) => {
  const nameList = _.chain(picks)
    .map((e) => e.name)
    .unionBy()
    .value();
  const buyList = _.filter(picks, (e) => e.pickType === '매수');
  const sellList = _.filter(picks, (e) => e.pickType === '매도');

  const totalPoint = _.chain(picks)
    .map((e) => Number(e.point))
    .reduce((e1, e2) => e1 + e2)
    .value();
  return (
    <div className="chart-area">
      <div className="chart-style__03">
        <div className="txt">
          <h4>방장님의 현재픽 정보</h4>
          <h4>
            {_.isEmpty(picks)
              ? ''
              : `${nameList[0]} ${nameList.length > 1 ? `외 ${nameList.length - 1} 종목` : ''} 매수중`}
          </h4>
        </div>
        <div className="sub-txt">
          <span>매수 {_.isEmpty(buyList) ? 0 : buyList.length}종목</span>
          <span>매도 {_.isEmpty(sellList) ? 0 : sellList.length}종목</span>
          <span>총투자 포인트 {_.isEmpty(picks) ? 0 : totalPoint}</span>
          {/* <span>(+1.6%)</span> */}
        </div>
      </div>
      <div className="modal-tab-content">
        <div>chart area1</div>
      </div>
    </div>
  );
};

const ChatHeader = ({
  name,
  createrName,
  onClose,
}: {
  name: string;
  createrName: string;
  onClose: any;
}) => (
  <div className="title">
    <h3>
      {name}
      <span className="span-red">{createrName}</span>
    </h3>
    <div className="title-btn-area">
      <button type="button" className="m-btn" id="waitingRoomBtn">
        대기실
      </button>
      <button onClick={onClose} type="button" className="close-btn">
        <img src={close} alt="" />
      </button>
    </div>
  </div>
);

const ChatLine = ({
  messages,
  sendMessage,
  message,
  setMessage,
}: {
  messages: any[];
  sendMessage: any;
  message: string;
  setMessage: any;
}) => {
  return (
    <div className="chat">
      <div className="content scroll">
        <ul>
          {messages.map((e, idx) => (
            <li key={idx}>
              <span className="id">{e.sender}</span>
              <span className="txt">{e.content}</span>
            </li>
          ))}
        </ul>
      </div>
      {/* .content end */}
      <div className="in-chat">
        {/* <div className="floating">
          <ul>
            <li className="span-red">에바엘피</li>
            <li>댓글 내용입니다.</li>
          </ul>
        </div> */}
        <div className="input-chat">
          <input
            type="text"
            name=""
            id=""
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            onKeyDown={(e) => e.key === 'Enter' && sendMessage()}
            placeholder="채팅을 입력해주세요."
          />
          <div className="enter">
            <Link
              to={'#/'}
              onClick={(e) => {
                e.preventDefault();
                sendMessage();
              }}
            >
              <figure>
                <img src={chatUp} alt="" />
              </figure>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

const ChatStatus = ({
  createrName,
  rank,
  positionCutCnt,
  point,
  picks,
}: ChatRoomDetailData) => (
  <div className="status">
    <div className="title">
      <img src={avatar} alt="사용자 아이콘" />
      <p>
        <span>{createrName}</span> 님의 운용현황
      </p>
    </div>
    <div className="status-info">
      <ul>
        <li>
          <h4>순위</h4>
          <span>전체 {rank ?? '-'}위</span>
        </li>
        <li>
          <h4>포지션 마감</h4>
          <span>{positionCutCnt ?? '-'}회</span>
        </li>
        <li>
          <h4>보유포인트</h4>
          <span>{point}점</span>
        </li>
      </ul>
    </div>
    <div className="status-info__02 scroll">
      <ul>
        {picks?.map((e, idx) => (
          <li key={idx}>
            <div>
              <h5>{e.pickType}</h5>
            </div>
            <span>
              [{e.name}] {e.point} ( {Number(e.rate) >= 0 ? '+' : '-'} {e.rate}%
              )
            </span>
          </li>
        ))}
      </ul>
    </div>
  </div>
);

export const ChatPick = ({
  userName,
  picks,
}: {
  userName: string;
  picks?: UserPickInfo[];
}) => {
  return (
    <div className="pick">
      <div className="title">
        <img src={avatar} alt="사용자 아이콘" />
        <p>
          <span>{userName}</span> 님의 전체픽
        </p>
      </div>
      <div className="content scroll">
        <ul>
          {picks?.map((e, idx) => (
            <li key={idx}>
              <div className="pick-title">
                <h4>{e.pickStatus}</h4>
                <p>획득 스코어 {e.point}점</p>
              </div>
              <div className="pick-status">
                <div>
                  <h5>{e.pickType}</h5>
                </div>
                <p>{e.pickEndDatetime}</p>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export const ChatUser = ({
  ownerId,
  users,
}: {
  ownerId: string;
  users?: ChatUserInfo[];
}) => {
  return (
    <div className="chat-users">
      <div className="title">
        <img src={chat} alt="채팅참여자 아이콘" />
        <h3>채팅방 참여자</h3>
      </div>
      <div className="chat-users-list scroll">
        <ul>
          {users?.map((e, idx) => (
            <li className="owner" key={idx}>
              <img src={e.profileImgUrl ?? chaUser} alt="사진" />
              <span>{e.userName}</span>
              {e.userId === ownerId ? (
                <div className="owner">
                  <img src={crown} alt="방장아이콘" />
                </div>
              ) : null}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
