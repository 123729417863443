import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import '../assets/css/toast.css';

export const showToast = (message: string) => {
  toast.success(message, {
    position: 'top-center', // 최신 버전에서는 문자열로 위치를 지정합니다.
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

const Toast = () => {
  return <ToastContainer />;
};

export default Toast;
