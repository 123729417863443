import { Link } from 'react-router-dom';

export const Footer = () => (
  <footer className="footer">
    <div className="inner">
      <ul className="list">
        <li>
          <Link to="https://setrade.asia/">Setrade.asia</Link>
        </li>
        <li>
          <Link to="https://setrade.asia/">광고/제휴</Link>
        </li>
        <li>
          <Link to="#/">이용약관</Link>
        </li>
        <li>
          <Link to="#/">개인정보처리방침</Link>
        </li>
        <li>
          <Link to="#/">청소년보호정책</Link>
        </li>
        <li>
          <Link to="#/">이메일무단수집거부</Link>
        </li>
        <li>
          <Link to="#/">고객센터</Link>
        </li>
      </ul>
      <div className="txt">
        <p>
          <span className="span-red">SETRADE COMMUNITY</span> 은/는 만 19세
          미만의 청소년은 가입이 불가능한 사이트입니다. 본인이 아닌 타인에게
          회원가입 인증을 대여하는 행위는 금지되어 있습니다.
        </p>
      </div>
      <div className="info">
        <p>
          <span>SETRADE COMMUNITY</span> 은/는 등록된 상표로 법적보호를
          받습니다.<br></br>
          <span>SETRADE COMMUNITY</span> 명칭을 본사의 허락없이 무단으로 사용할
          경우 법적제재를 받을수 있습니다.
        </p>
      </div>
    </div>
    <div className="copyright">
      COPYRIGHT © setrade.asis ALL RIGHTS RESERVED.
    </div>
  </footer>
);
