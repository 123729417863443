import React, { useState } from 'react';
import '../../assets/css/chatRoomCreateModal.css'; // 반응형 채팅방 생성 팝업을 위한 CSS 파일
import '../../assets/css/modal.css'; // 팝업 디자인을 위한 공통 CSS 파일

interface ChatRoomCreateModalProps {
  isOpen: boolean;
  onClose: () => void;
  onCreate: (roomName: string) => void;
}

const ChatRoomCreateModal: React.FC<ChatRoomCreateModalProps> = ({
  isOpen,
  onClose,
  onCreate,
}) => {
  const [roomName, setRoomName] = useState('');

  const handleCreate = () => {
    if (roomName.trim() !== '') {
      onCreate(roomName);
      setRoomName('');
      onClose();
    }
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content chat-room-create-modal">
        <h2>채팅방 만들기</h2>
        <input
          type="text"
          className="chat-room-input"
          placeholder="채팅방 이름을 입력하세요"
          value={roomName}
          onChange={(e) => setRoomName(e.target.value)}
        />
        <div className="modal-buttons">
          <button className="modal-confirm-btn" onClick={handleCreate}>
            생성하기
          </button>
          <button className="modal-cancel-btn" onClick={onClose}>
            취소
          </button>
        </div>
      </div>
    </div>
  );
};

export default ChatRoomCreateModal;
