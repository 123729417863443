/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useLocation, useNavigate } from 'react-router-dom';
// import '../../assets/css/common.css';
// import '../../assets/css/join.css';
// import '../../assets/css/responsive.css';
import '../../assets/css/writerContent.css'; // 새로운 CSS 파일
import { createPost } from '../../common/api/board'; // API 호출 함수 임포트
import ConfirmModal from '../../components/ConfirmModal'; // ConfirmModal 컴포넌트 임포트
import { showToast } from '../../components/Toast'; // Toast 컴포넌트 임포트

export const WriterContent = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [errors, setErrors] = useState({ title: false, content: false });
  const [isModalOpen, setIsModalOpen] = useState(false);

  const category = location.state.title ?? '기본';
  const boardType = location.state.boardType ?? 'NEWS';
  const handleTitleChange = (e: any) => {
    setTitle(e.target.value);
    setErrors((prevErrors) => ({ ...prevErrors, title: false }));
  };

  const handleContentChange = (value: any) => {
    setContent(value);
    setErrors((prevErrors) => ({ ...prevErrors, content: false }));
  };

  const handleSubmit = () => {
    let hasError = false;

    if (title.trim() === '') {
      setErrors((prevErrors) => ({ ...prevErrors, title: true }));
      hasError = true;
    }

    if (content.trim() === '') {
      setErrors((prevErrors) => ({ ...prevErrors, content: true }));
      hasError = true;
    }

    if (hasError) return;

    setIsModalOpen(true); // 모달 열기
  };

  const handleConfirm = async () => {
    setIsModalOpen(false);
    try {
      await createPost(title, content, boardType);
      showToast('게시물 등록 성공'); // 토스트 알림 표시
      setTimeout(() => {
        navigate(-1); // 예시로 게시판 목록 페이지로 이동
      }, 3000); // 3초 후에 이동
    } catch (error) {
      showToast('게시물 등록 실패'); // 토스트 알림 표시
      console.error('Failed to create post:', error);
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false); // 모달 닫기
    navigate(-1); // 예시로 게시판 목록 페이지로 이동
  };

  return (
    <>
      <main className="main writer-content">
        <section className="write">
          <div className="inner">
            <div className="title">
              <h3>
                <span>{category} 게시판 글쓰기</span>
              </h3>
            </div>
            <div className="content">
              <div className="content-inner">
                <div className="user-id-area">
                  <input
                    type="text"
                    placeholder="제목"
                    className={`join-input ${errors.title ? 'error' : ''}`}
                    value={title}
                    onChange={handleTitleChange}
                    style={{ width: '100%' }}
                  />
                  {errors.title && (
                    <small className="error-text">제목은 필수값입니다.</small>
                  )}
                </div>
                <div className="user-nickname-area">
                  <ReactQuill
                    theme="snow"
                    value={content}
                    onChange={handleContentChange}
                    placeholder="글내용"
                    style={{ width: '100%' }}
                    className={errors.content ? 'error' : ''}
                  />
                  {errors.content && (
                    <small className="error-text">본문은 필수값입니다.</small>
                  )}
                </div>
                <div className="join-btn-area">
                  <button
                    type="button"
                    className="join-btn"
                    onClick={handleSubmit}
                  >
                    글등록
                  </button>
                  <button
                    type="button"
                    className="cancel-btn"
                    onClick={handleCancel}
                  >
                    취소
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <ConfirmModal
        isOpen={isModalOpen}
        message="게시물을 등록하시겠습니까?" // 메시지 전달
        onConfirm={handleConfirm}
        onCancel={() => setIsModalOpen(false)}
      />
    </>
  );
};
