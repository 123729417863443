import { Footer } from './Footer';
import { Header } from './Header';

export const PageLayout = ({ page }: { page?: JSX.Element }) => (
  <>
    <Header />
    {page}
    <Footer />
  </>
);
