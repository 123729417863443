import { useNavigate } from 'react-router-dom';
import '../assets/css/error.css'; // 오류 페이지 디자인을 위한 CSS 파일

const ErrorPage = ({
  message = '페이지를 찾을 수 없습니다.',
  statusCode = 404,
}) => {
  const navigate = useNavigate();

  const handleGoHome = () => {
    navigate('/');
  };

  return (
    <div className="error-container">
      <div className="error-content">
        <h1 className="error-code">{statusCode}</h1>
        <p className="error-message">{message}</p>
        <button className="error-button" onClick={handleGoHome}>
          홈으로 이동
        </button>
      </div>
    </div>
  );
};

export default ErrorPage;
