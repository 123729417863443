/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import nextArrow from '../assets/img/next.svg';
import prevArrow from '../assets/img/prev.svg';

export const Pager = ({
  totalItems,
  itemsPerPage,
  currentPage,
  onPageChange,
}: {
  totalItems: number;
  itemsPerPage: number;
  currentPage: number;
  onPageChange: any;
}) => {
  const [pages, setPages] = useState<number[]>([]);
  const [currentGroup, setCurrentGroup] = useState(0);

  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const pagesPerGroup = 5;

  useEffect(() => {
    const startPage = currentGroup * pagesPerGroup + 1;
    const endPage = Math.min(startPage + pagesPerGroup - 1, totalPages);
    const newPages: number[] = [];

    for (let i = startPage; i <= endPage; i++) {
      newPages.push(i);
    }

    setPages(newPages);
  }, [currentGroup, totalPages]);

  const handlePrevGroup = () => {
    if (currentGroup > 0) {
      setCurrentGroup(currentGroup - 1);
    }
  };

  const handleNextGroup = () => {
    if ((currentGroup + 1) * pagesPerGroup < totalPages) {
      setCurrentGroup(currentGroup + 1);
    }
  };

  const handlePageChange = (page: any) => {
    onPageChange(page);
  };

  return (
    <div className="pagination">
      <ul className="paging">
        <li className={`page ${currentGroup === 0 ? 'disabled' : ''}`}>
          <Link to={'#'} onClick={handlePrevGroup}>
            <img src={prevArrow} alt="Previous" />
          </Link>
        </li>
        {pages.map((page) => (
          <li
            key={page}
            className={`page ${page === currentPage ? 'active' : ''}`}
          >
            <Link to={'#'} onClick={() => handlePageChange(page)}>
              {page}
            </Link>
          </li>
        ))}
        <li
          className={`page ${(currentGroup + 1) * pagesPerGroup >= totalPages ? 'disabled' : ''}`}
        >
          <Link to={'#'} onClick={handleNextGroup}>
            <img src={nextArrow} alt="Next" />
          </Link>
        </li>
      </ul>
    </div>
  );
};
