/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Link } from 'react-router-dom';
import close from '../../assets/img/close.svg';
import crown from '../../assets/img/mdi_crown.svg';
import waiting from '../../assets/img/waiting-temp.png';
import { createChatRoom, getChatRoomList } from '../../common/api/chat';
import { ChatRoomData } from '../../common/data/data-type';
import ChatRoomCreateModal from './ChatRoomCreateModal';

/**
 * 대기방 모달창
 * @param param0
 * @returns
 */
export const ChatWaitRoomModal = ({
  show,
  setShow,
  chatShow,
  setChatShow,
}: {
  show: boolean;
  setShow: any;
  chatShow: string | undefined;
  setChatShow: any;
}) => {
  // const [chatRoom, setChatRoom] = useState<ChatRoomData[]>([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const queryClient = useQueryClient();

  const { mutate } = useMutation(createChatRoom, {
    onSuccess: () => {
      // 채팅방이 성공적으로 개설되면 'getChatRoomList' 캐시를 무효화하여 다시 가져오도록 설정
      queryClient.invalidateQueries('getChatRoomList');
    },
  });
  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleCreateRoom = async (roomName: string) => {
    console.log('New room created:', roomName);
    mutate(roomName);
  };
  const { data: chatRoom } = useQuery<ChatRoomData[], Error>(
    'getChatRoomList',
    () => getChatRoomList()
  );

  return show ? (
    <>
      <div
        onClick={() => {
          document.body.style.overflow = 'auto';
          setShow(false);
        }}
        className="modal-bg"
      ></div>
      <div className="modal-chat-waiting" id="modal-chat-waiting">
        <div className="inner">
          <div className="title">
            <h3>대기실</h3>
            <div className="title-btn-area">
              <div className="select-area">
                <select className="chat-select" name="" id="">
                  <option value="">기본</option>
                  <option value="">추천</option>
                  <option value="">방장 순위</option>
                  <option value="">접속자 수</option>
                </select>
              </div>
              <button type="button" className="m-btn" onClick={handleOpenModal}>
                방개설
              </button>
              <button
                onClick={() => setShow(false)}
                type="button"
                className="close-btn"
              >
                <img src={close} alt="" />
              </button>
            </div>
          </div>
          <div className="content scroll">
            <ul className="list">
              {chatRoom?.map((e, idx) => (
                <ChatRoomElement
                  key={idx}
                  id={e.id}
                  point={e.point}
                  createdBy={e.createdBy}
                  name={e.name}
                  createrName={e.createrName}
                  isPicked={e.isPicked}
                  userCnt={e.userCnt}
                  maxUsers={e.maxUsers}
                  chatShow={chatShow}
                  setChatShow={setChatShow}
                />
              ))}
            </ul>
          </div>
        </div>
      </div>
      <ChatRoomCreateModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        onCreate={handleCreateRoom}
      />
    </>
  ) : null;
};

/**
 * 채팅방 컴포넌트
 * @param param0
 * @returns
 */
const ChatRoomElement = ({
  id,
  name,
  createrName,
  point,
  userCnt,
  isPicked,
  maxUsers,
  chatShow,
  setChatShow,
}: ChatRoomData & { setChatShow: any; chatShow: string | undefined }) => (
  <li>
    <Link
      to="#/"
      onClick={(e) => {
        e.preventDefault();
        if (!chatShow) {
          setChatShow(id);
        }
      }}
    >
      <div className="chat-title">
        <div className="image">
          <img src={waiting} alt="" />
        </div>
        <div className="room-title">
          <h4>{name}</h4>
          <p>
            <img src={crown} alt="" />
            {createrName}
          </p>
        </div>
      </div>
      <div className="room-status">
        <p>
          보유포인트 :<span className="point">{point}점</span>
          <span className="span-red">{userCnt}</span>명 참가중 / {maxUsers}
        </p>
        <div>{isPicked ? '방장픽' : '대기중'}</div>
      </div>
    </Link>
  </li>
);
