/* eslint-disable @typescript-eslint/no-explicit-any */
import api from './api';

/**
 * 인증 관련 API
 */
export const login = async (loginId: string, password: string) => {
  const response = await api.post('/login', { loginId, password });
  return response?.data;
};

export const logout = async (userId: string, loginId: string) => {
  const response = await api.post('/logout', { loginId });
  return response?.data;
};

export const signUp = async (userData: any) => {
  const response = await api.post('/users', userData);
  return response?.data;
};

export const sendAuthCode = async (userId: number, phoneNumber: string) => {
  const response = await api.post('/users/send-auth-code', {
    userId,
    phoneNumber,
  });
  return response?.data;
};

export const verifyAuthCode = async (userId: number, authNum: number) => {
  const response = await api.post('/users/verify-auth-code', {
    userId,
    authNum,
  });
  return response?.data;
};

export const verifyAuthKeyRegister = async (
  authKey: string,
  authVal: string
) => {
  const response = await api.post('/users/verify-auth-code-register', {
    authKey,
    authVal,
  });
  return response?.data;
};

export const verifyAuthCodeByEmail = async (email: string, authNum: number) => {
  const response = await api.post('/users/verify-auth-code-email', {
    email,
    authNum,
  });
  return response.data;
};

export const verifyAuthCodeByEmailAndCellNumber = async (
  email: string,
  cellNumber: string,
  authNum: number
) => {
  const response = await api.post('/users/verify-auth-code-email-phone', {
    email,
    cellNumber,
    authNum,
  });
  return response?.data;
};

export const verifyAuthCodeByLoginIdAndCellNumber = async (
  loginId: string,
  cellNumber: string,
  authNum: number
) => {
  const response = await api.post('/users/verify-auth-code-loginId-phone', {
    loginId,
    cellNumber,
    authNum,
  });
  return response?.data;
};
