/* eslint-disable @typescript-eslint/no-explicit-any */
import _ from 'lodash';
import { MouseEventHandler, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import SockJS from 'sockjs-client';
import Stomp from 'stompjs';
import live from '../../assets/img/live-red.svg';
import chatUp from '../../assets/img/mingcute_up-fill.svg';
import waitTempImg from '../../assets/img/wait.png';
import { getChatRoomList } from '../../common/api/chat';
import { ChatRoomData } from '../../common/data/data-type';
import { config } from '../../config/config';

export const WideChat = ({
  waitShow,
  setWaitShow,
  setChatShow,
}: {
  waitShow: boolean;
  setWaitShow: React.Dispatch<React.SetStateAction<boolean>>;
  setChatShow: React.Dispatch<React.SetStateAction<string | undefined>>;
}) => {
  const [participants, setParticipants] = useState<string>('0');
  const [messages, setMessages] = useState<any[]>([]);
  const [message, setMessage] = useState('');
  const [stompClient, setStompClient] = useState<Stomp.Client | null>(null);
  const session = JSON.parse(
    localStorage.getItem('setrade_session') ?? '{"userName" : "Guest"}'
  );

  const {
    data: chatRoom = [],
    isLoading,
    isError,
  } = useQuery<ChatRoomData[], Error>('getChatRoomList', () =>
    getChatRoomList()
  );
  useEffect(() => {
    const socket = new SockJS(`${config.api.protocol}://${config.api.host}/ws`);
    const client = Stomp.over(socket);

    client.connect({}, () => {
      client.subscribe('/topic/public/addmessage', (msg) => {
        const newMessage = JSON.parse(msg.body);
        if (_.trim(newMessage) && _.trim(newMessage) !== '') {
          const el = [...messages];
          el.push(newMessage);
          setMessages(el);
        }
      });

      client.subscribe('/topic/public/addmember', (msg) => {
        const newMessage = JSON.parse(msg.body);
        if (_.trim(newMessage) && _.trim(newMessage) !== '') {
          const el = [...messages];
          el.push(`${newMessage.sender} 님이 채팅에 참가하셨습니다.`);
          setMessages(el);
        }
      });

      client.subscribe('/topic/public/removemember', (msg) => {
        const newMessage = JSON.parse(msg.body);
        if (
          newMessage &&
          _.trim(newMessage.content) &&
          _.trim(newMessage.content) !== ''
        ) {
          setMessages((prevMessages) => [
            ...prevMessages,
            JSON.parse(msg.body),
          ]);
        }
      });

      client.subscribe('/topic/participants', (msg) => {
        setParticipants(JSON.parse(msg.body));
      });

      const joinMessage = {
        content: `참가`,
        sender: `${session?.userName ?? 'Guest'} ${session?.userName !== 'Guest' ? `( Lv.${session.level ?? 0} )` : ''} `,
        type: 'JOIN',
      };
      client.send('/app/chat.addUser', {}, JSON.stringify(joinMessage));
    });

    setStompClient(client);

    return () => {
      if (stompClient) {
        const joinMessage = {
          content: `퇴장`,
          sender: `${session?.userName ?? 'Guest'} ${session?.userName !== 'Guest' ? `( Lv.${session.level ?? 0} )` : ''} `,
          type: 'LEAVE',
        };
        stompClient.send(
          '/app/chat.removeUser',
          {},
          JSON.stringify(joinMessage)
        );
        stompClient.disconnect(() => {});
      }

      document.body.style.overflow = 'auto';
      setWaitShow(false);
      setChatShow(undefined);
    };
    // }, []);
  }, []);

  const sendMessage = () => {
    if (stompClient && _.trim(message) && _.trim(message) !== '') {
      const chatMessage = {
        sender: `${session?.userName ?? 'Guest'} ${session?.userName !== 'Guest' ? `( Lv.${session.level ?? 0} )` : ''} `, // 로그인한 유저의 ID
        content: message,
        type: 'CHAT',
      };
      stompClient.send(
        '/app/chat.sendMessage',
        {},
        JSON.stringify(chatMessage)
      );
      setMessage('');
    }
  };

  return (
    <>
      <div className="chat-summary">
        <div className="waiting">
          <Link
            to="#/"
            onClick={(e) => {
              document.body.style.overflow = 'hidden';
              e.preventDefault();
              setWaitShow(!waitShow);
            }}
          >
            대기실
          </Link>
          <div>
            <ul className="list">
              {chatRoom?.length > 0 && !isLoading && !isError
                ? chatRoom.map((e, idx) => (
                    <ChatChannel
                      onClick={() => {
                        document.body.style.overflow = 'hidden';
                        setChatShow(e.id);
                      }}
                      key={idx}
                      {...e}
                    />
                  ))
                : null}
            </ul>
          </div>
        </div>

        <div className="live-chat">
          <div className="title">
            <figure>
              <img src={live} alt=""></img>
            </figure>
            <h3>Live Chat</h3>
          </div>
          <div className="chat">
            <div className="title">
              <span>참여자</span>
              <span className="span-red">{participants} 명</span>
            </div>
            <div className="content scroll">
              <ul>
                {messages.map((e, idx) => (
                  <PushMessage
                    key={idx}
                    sender={e.sender}
                    content={e.content}
                  />
                ))}
              </ul>
            </div>
            <div className="in-chat">
              <div className="input-chat">
                <input
                  type="text"
                  placeholder="채팅을 입력해주세요."
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  onKeyDown={(e) => e.key === 'Enter' && sendMessage()}
                ></input>
                <div className="enter">
                  <Link
                    to="#/"
                    onClick={(e) => {
                      e.preventDefault();
                      sendMessage();
                    }}
                  >
                    <figure>
                      <img src={chatUp} alt=""></img>
                    </figure>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

/**
 * 개설된 채팅방 컴포넌트
 * @returns
 */
// eslint-disable-next-line @typescript-eslint/ban-types
const ChatChannel = ({
  userCnt,
  name,
  ownerImg,
  maxUsers,
  onClick,
}: {
  name?: string;
  ownerImg?: string;
  userCnt?: string;
  maxUsers?: string;
  onClick?: MouseEventHandler<HTMLElement>;
}) => (
  <li>
    <Link
      to="#/"
      onClick={(e) => {
        e.preventDefault();
        onClick && onClick(e);
      }}
    >
      <figure>
        <img
          src={ownerImg ? ownerImg : waitTempImg}
          alt="대기실 임시이미지"
        ></img>
      </figure>
    </Link>
    <div className="chat-tooltip-right">
      <div className="top">
        <span>참여자</span>
        <span className="span-red">
          {userCnt ? userCnt : 0} / {maxUsers}
        </span>
      </div>
      <h3>{name}</h3>
      <div className="chat-btn-area">
        <button onClick={onClick} type="button" className="chat-btn">
          채팅 참여
        </button>
      </div>
    </div>
  </li>
);

const PushMessage = ({
  sender,
  content,
}: {
  sender: string;
  content: string;
}) => (
  <li>
    <span className="id">{sender}</span>
    <span className="txt">{content}</span>
  </li>
);
