/* eslint-disable @typescript-eslint/no-explicit-any */
import _ from 'lodash';
import { useCallback, useState } from 'react';
import '../../assets/css/findid.css';
import {
  verifyAuthCodeByEmail,
  verifyAuthCodeByEmailAndCellNumber,
} from '../../common/api/auth';
import {
  getUserId,
  sendEmailVerification,
  sendSmsVerificationWithEmail,
} from '../../common/api/user';
import { validateEmail, validatePhone } from '../../common/util/validator';

export const FindId = () => {
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [emailVerified, setEmailVerified] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [emailTimer, setEmailTimer] = useState(300); // 5분 타이머

  const [phone, setPhone] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [phoneVerified, setPhoneVerified] = useState(false);
  const [phoneSent, setPhoneSent] = useState(false);
  const [phoneTimer, setPhoneTimer] = useState(90); // 1분 30초 타이머

  const handleEmailVerification = useCallback(async () => {
    if (!validateEmail(email)) {
      setEmailError('이메일 형식이 잘못되었습니다.');
      return;
    }
    setEmailError('');

    try {
      await sendEmailVerification(email);
      setEmailSent(true);
      startEmailTimer();
    } catch (error) {
      setEmailError('등록된 유저가 없습니다.');
    }
  }, [email]);

  const handlePhoneVerification = useCallback(async () => {
    if (!emailVerified) {
      alert('이메일 인증을 먼저 하세요.');
      return;
    }

    if (!validatePhone(phone)) {
      setPhoneError('전화번호 형식이 잘못되었습니다.');
      return;
    }
    setPhoneError('');

    try {
      await sendSmsVerificationWithEmail(email, phone);
      setPhoneSent(true);
      startPhoneTimer();
    } catch (error) {
      setPhoneError('회원정보를 찾을 수 없습니다.');
    }
  }, [emailVerified, phone, email]);

  const handleFindUserId = useCallback(async () => {
    try {
      const userId = await getUserId(email, phone);
      alert(`회원님의 로그인 ID는 ${userId}입니다.`);
    } catch (error) {
      alert('회원 정보를 찾을 수 없습니다.');
    }
  }, [phone, email]);

  const startEmailTimer = () => {
    setEmailTimer(300);
    const interval = setInterval(() => {
      setEmailTimer((prev) => {
        if (prev <= 1) {
          clearInterval(interval);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);
  };

  const startPhoneTimer = () => {
    setPhoneTimer(90);
    const interval = setInterval(() => {
      setPhoneTimer((prev) => {
        if (prev <= 1) {
          clearInterval(interval);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);
  };

  const checkValidEmailAuthCode = useCallback(
    async (e: any) => {
      const code = e.target.value;

      if (!emailSent || _.isEmpty(code) || code.length() !== 6) {
        return;
      }

      try {
        await verifyAuthCodeByEmail(email, code);
        setEmailVerified(true);
      } catch (error) {
        alert('인증번호 오류');
        setEmailVerified(false);
      }
    },
    [email, emailSent]
  );

  const checkValidEmailSmsAuthCode = useCallback(
    async (e: any) => {
      const code = e.target.value;

      if (!emailSent || !phoneSent || _.isEmpty(code) || code.length() !== 6) {
        return;
      }

      try {
        await verifyAuthCodeByEmailAndCellNumber(email, phone, code);
        setPhoneVerified(true);
      } catch (error) {
        alert('인증번호 오류');
        setPhoneVerified(false);
      }
    },
    [email, emailSent, phone, phoneSent]
  );

  return (
    <main className="findid-main">
      <section className="findid-section">
        <div className="inner">
          <div className="title">
            <h3 className="service-name">
              <span className="set">SET</span>RADE COMMUNITY 아이디 찾기
            </h3>
          </div>
          <div className="content">
            <div className="content-inner">
              <div className="user-email-area">
                <input
                  type="text"
                  placeholder="이메일"
                  className={`join-input ${emailError ? 'error' : ''}`}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  disabled={emailVerified}
                />
                <button
                  type="button"
                  className="cert-btn"
                  onClick={handleEmailVerification}
                  disabled={emailVerified}
                >
                  이메일 본인인증
                </button>
              </div>
              {emailError && <small className="error-text">{emailError}</small>}
              {emailSent && (
                <div className="auth-code-area">
                  <input
                    type="text"
                    placeholder="인증번호"
                    className={`join-input ${emailVerified ? 'success' : ''}`}
                    onChange={checkValidEmailAuthCode}
                    disabled={emailVerified}
                  />
                  <span>
                    {emailTimer > 0
                      ? `${Math.floor(emailTimer / 60)}:${emailTimer % 60}`
                      : '인증시간 만료'}
                  </span>
                  <div className={`timer ${emailVerified ? 'success' : ''}`}>
                    {emailVerified
                      ? '인증 완료'
                      : emailTimer > 0
                        ? `${Math.floor(emailTimer / 60)}:${emailTimer % 60 < 10 ? `0${emailTimer % 60}` : emailTimer % 60}`
                        : '인증시간 만료'}
                  </div>
                </div>
              )}
              <div className="user-tel-area">
                <input
                  type="number"
                  placeholder="전화번호"
                  className={`join-input ${phoneError ? 'error' : ''}`}
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  disabled={phoneVerified}
                />
                <button
                  type="button"
                  className="cert-btn"
                  onClick={handlePhoneVerification}
                  disabled={phoneVerified}
                >
                  휴대전화 인증
                </button>
              </div>
              {phoneError && <small className="error-text">{phoneError}</small>}
              {phoneSent && (
                <div className="auth-code-area">
                  <input
                    type="text"
                    placeholder="인증번호"
                    className={`join-input ${phoneVerified ? 'success' : ''}`}
                    onChange={checkValidEmailSmsAuthCode}
                    disabled={phoneVerified}
                  />
                  <div className={`timer ${phoneVerified ? 'success' : ''}`}>
                    {phoneVerified
                      ? '인증 완료'
                      : phoneTimer > 0
                        ? `${Math.floor(phoneTimer / 60)}:${phoneTimer % 60 < 10 ? `0${phoneTimer % 60}` : phoneTimer % 60}`
                        : '인증시간 만료'}
                  </div>
                </div>
              )}
              <div className="join-btn-area">
                <button
                  type="button"
                  className="join-btn"
                  onClick={handleFindUserId}
                  disabled={!emailVerified || !phoneVerified}
                >
                  아이디 찾기
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};
